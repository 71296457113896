import React, { useState } from "react";
import { FaCartPlus } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { FiMinus, FiPlus } from "react-icons/fi";
import { asyncAddToCart, asyncRemoveItem, asyncUpdateQuantity } from "../../actions/searchProductAction";
import { AppDispatch } from "../../store/store";
import Loader from "../../common/component/loader/Loader";
import Pagination from "./Pagination";
import { useMediaQuery } from "@react-hook/media-query";
import { useNavigate } from "react-router-dom";

interface ProductListProps {
  products: any[];
  totalPages: any;
  currentPage: any;
  onPageChange: any;
}

const ProductList: React.FC<ProductListProps> = ({ products, totalPages, currentPage, onPageChange }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [cart, setCart] = useState<{ [key: string]: number }>({});
  const [loadingProductId, setLoadingProductId] = useState<string | null>(null);
  // const isMobile = useMediaQuery("(max-width: 870px)");
  const navigate = useNavigate()
  const handleAddToCart = async (product: any, index: number) => {
    const productId = product._id;
    setLoadingProductId(productId);

    try {
      await dispatch(asyncAddToCart(product, index));
      setCart((prevCart) => ({ ...prevCart, [productId]: 1 }));
    } catch (error) {
      console.error("Failed to add product to cart:", error);
    } finally {
      setLoadingProductId(null);
    }
  };

  const handleUpdateQuantity = async (product: any, index: number, quantity: number) => {
    const productId = product._id;
    setLoadingProductId(productId);

    try {
      if (quantity === 0) {
        console.log(`Removing product from cart:`, { productId });
        await dispatch(asyncRemoveItem(product, index));
        setCart((prevCart) => ({ ...prevCart, [productId]: quantity }));
      } else {
        await dispatch(asyncUpdateQuantity(product, index, quantity));
        setCart((prevCart) => ({ ...prevCart, [productId]: quantity }));
      }
    } catch (error) {
      console.error("Failed to update quantity:", error);
    } finally {
      setLoadingProductId(null);
    }
  };

  if (!Array.isArray(products) || products.length === 0) {
    return (
      <div className="text-center text-red-500">
        No products available to display.
      </div>
    );
  }
  

  return (
    <div className="grid gap-2">
      {products.map((product, index) => {
        const discount = Math.round(
          ((product.regularPrice - product.salePrice) / product.regularPrice) * 100
        );
        const productId = product._id;
        const quantity = cart[productId] || 0;

        return (
          <div
            key={productId}

            className="p-2 m-2 border-t-2 border-gray-300 flex smm:flex-col flex-row items-center smm:items-start justify-center w-full "
          >
            {/* Product Image */}
            <div className=" flex flex-row items-center w-[60%] smm:gap-[30%] gap-[20%] cursor-pointer" onClick={() => {
              const formattedProductName = product.productName
                ?.trim()
                .replace(/,/g, "") // Remove commas
                .replace(/\s+/g, "-"); // Replace spaces with "-"
              navigate(`/product?name=${formattedProductName}&product_id=${productId}`);
            }}>
              <div className="flex-shrink-0 w-[40%] h-36 relative z-[-1] ">
                <img
                  src={product.images?.[0]?.url || "/placeholder-image.png"}
                  alt={product.productName || "Product Image"}
                  className={` w-full h-36 object-contain rounded-md ${product.totalStock === 0 ? "opacity-30" : ""
                    }`}
                />
                {product.totalStock === 0 && (
                  <div className=" absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                    <p className="text-white font-bold text-sm">Out of Stock</p>
                  </div>
                )}
              </div>

              {/* Product Details */}
              <div className="flex-1 m-2 w-[80%]"  >
                <h3 className="mb-2">{product.productName}</h3>
                <p className="text-sm text-red-500 font-bold">{discount}% off</p>

                <div className="flex items-center space-x-2 mb-3">
                  <p className="text-sm text-gray-500 line-through">
                    ₹{product.regularPrice?.toLocaleString() || "0"}
                  </p>
                  <p className="text-lg text-green-600 font-semibold">
                    ₹{product.salePrice?.toLocaleString() || "0"}
                  </p>
                </div>
              </div>
            </div>

            {/* Add to Cart / Quantity Buttons */}
            <div className="flex flex-col md:flex-row items-center  smm:items-end gap-5 justify-end w-[40%] ml-[100px]">
              {product.totalStock > 0 ? (
                quantity > 0 ? (
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleUpdateQuantity(product, index, quantity - 1)}
                      className="p-1 rounded-full border"
                      disabled={loadingProductId === productId}
                    >
                      <FiMinus size={16} />
                    </button>
                    <span className="px-4 text-lg font-semibold">{quantity}</span>
                    <button
                      onClick={() => handleUpdateQuantity(product, index, quantity + 1)}
                      className="p-1 rounded-full border"
                      disabled={
                        loadingProductId === productId || quantity >= product.totalStock
                      }
                    >
                      <FiPlus size={16} />
                    </button>
                  </div>
                ) : loadingProductId === productId ? (
                  <div className="flex items-center justify-center">
                    <Loader />
                  </div>
                ) : (
                  <button
                    onClick={() => handleAddToCart(product, index)}
                    className=" flex items-center gap-2 bg-indigo-700 text-white h-[33.13px] text-center  sm:m-0 my-[0.7rem] rounded-lg  p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem] "
                    disabled={loadingProductId === productId}
                  >
                    <FaCartPlus size={16} />
                    <span>Add to Cart</span>
                  </button>
                )
              ) : (

                null)}
            </div>


          </div>
        );
      })}

      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );


};

export default ProductList;
