import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductTabs from "./components/Tabs";
import ProductImage from "./components/ProductImage";
import { GetRequest } from "../../api/Request";
import Loader from "../../common/loader/Loader";
import ProductInfo from "./components/ProductInfo";
import { showErrorToast } from "../../utils/toast";
import ProductForSale from "../main/ProductForSale";
import { useSelector } from "react-redux";
import { allProduct } from "../../slice/CartSlice";
import NotFoundPage from "../notFoundPage/NotFoundPage";
import {  RenderHelmetForCat } from "../../utils/Helmet";

const ProductCard: React.FC = (props: any) => {
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const navigate = useNavigate(); // Add this line to get navigate function
  const [isLoadingForMainProductInfo, setIsLoadingForMainProductInfo] =
    useState<Boolean>(false);
   const [isProductNotFound, setIsProductNotFound] = useState<boolean>(false); // Track product not found state

  const [product, setProduct] = useState<any>({
    product: {},
    relatedProducts: [],
  });
  const cartProducts = useSelector(allProduct);
  const [selectedVariations, setSelectedVariations] = useState<{
    [key: string]: string;
  }>({});
  const [variations, setVariations] = useState<any>([]);

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, [query.get("product_id")]);

  useEffect(() => {
    // Check if product_id exists in the query params
    if (!query.get("product_id")) {
      setIsProductNotFound(true); // Set the state to true if product_id is not present
      return;
    } else {
      setIsProductNotFound(false); // Set to false if product_id is present
      fetchProductDetail(); // Fetch product details only if product_id is present
    }
  }, [query.get("product_id")]); // Depend on product_id change

  const fetchProductDetail = async () => {
    setIsLoadingForMainProductInfo(() => true);
    try {
      let response = await GetRequest(`/products/${query.get("product_id")}`);
      if (response.status === 200) {
        const variationsMap: any = {};
        const mainProduct = response.data.product;
        response.data.productsWithSameVariant.forEach((product: any) => {
          product.variation.forEach((variation: any) => {
            const { name, value } = variation;
            if (!variationsMap[name]) {
              variationsMap[name] = new Set();
            }
            variationsMap[name].add(value);
          });
        });

        setVariations(() => {
          return Object.keys(variationsMap).map((name) => {
            return {
              name,
              value: Array.from(variationsMap[name]),
            };
          });
        });

        const cartProduct =
          cartProducts &&
          cartProducts.find(
            (cartItem: any) => cartItem._id === mainProduct._id
          );

        const newRelatedProducts =
          response.data.relatedProducts.length > 0
            ? response.data.relatedProducts.map((product: any) => {
                const cartProduct =
                  cartProducts &&
                  cartProducts.find(
                    (cartItem: any) => cartItem._id === product._id
                  );
                return {
                  ...product,
                  addedToWishlist: false,
                  quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
                  addToCartStatus: cartProduct ? "Update Cart" : "Add to cart",
                  isLoading: false,
                };
              })
            : [];

        const newProductsWithSameVariant =
          response.data.productsWithSameVariant.length > 0
            ? response.data.productsWithSameVariant.map((product: any) => {
                const cartProduct =
                  cartProducts &&
                  cartProducts.find(
                    (cartItem: any) => cartItem._id === mainProduct._id
                  );
                return {
                  ...product,
                  addedToWishlist: false,
                  quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
                  addToCartStatus: cartProduct ? "Update Cart" : "Add to cart",
                };
              })
            : [];

        setProduct(() => {
          return {
            product: {
              ...response.data.product,
              addedToWishlist: false,
              quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
              addToCartStatus: cartProduct ? "Update Cart" : "Add to cart",
            },
            relatedProducts: newRelatedProducts,
            productsWithSameVariant: newProductsWithSameVariant,
          };
        });
      } else {
        navigate("/"); // Redirect to homepage if status is not 200
      }
    } catch (error: any) {
      showErrorToast(error.response.data);
      navigate("/"); // Redirect to homepage in case of an error
    }
    setIsLoadingForMainProductInfo(() => false);
  };

  const handleSelectVariant = (variation: any, value: string) => {
    let newProduct = product.productsWithSameVariant.filter((product: any) => {
      let variant = product?.variation.find((variant: any) => {
        return variant.name === variation.name && variant.value === value;
      });
      return variant !== undefined;
    });
    const cartProduct =
      cartProducts &&
      cartProducts.find((cartItem: any) => cartItem._id === newProduct[0]._id);
    setProduct((prevState: any) => {
      return {
        ...prevState,
        product: {
          ...newProduct[0],
          addedToWishlist: false,
          quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
          addToCartStatus: cartProduct ? "Update Cart" : "Add to cart",
          isLoading: false,
        },
      };
    });
  };


  if (isProductNotFound) {
    return <NotFoundPage />; // Render NotFoundPage if product is not found
  }

  return (
    <div className="mt-[5rem]">
       
        {/* Render only product-specific meta tags */}
        <RenderHelmetForCat metaInformations={product?.product?.metaInfo} />
   
      <h1 className="hidden">{product?.product?.metaInfo?.metaInfoTitle}</h1>
      <h2 className="hidden">{product?.product?.metaInfo?.metaInfoDescription}</h2>
      <h3 className="hidden">{product?.product?.metaInfo?.metaInfoKeyword?.join(", ")}</h3>
      <h4 className="hidden">Additional H4 content here</h4>
      <h5 className="hidden">Additional H5 content here</h5>
      <h6 className="hidden">Additional H6 content here</h6>
      {isLoadingForMainProductInfo ? (
        <div className="text-center h-[50vh] flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="max-w-[90%] sm:max-w-[97%] mx-auto">
            <div className="flex flex-wrap justify-between">
              <ProductImage product={product?.product} variations={variations} />
              <div className="mt-[1.6rem] basis-[62%] sm:basis-[100%] max-w-[100%]">
                <ProductInfo
                  variations={variations}
                  productsWithSameVariant={product?.productsWithSameVariant}
                  product={product?.product}
                  setProduct={setProduct}
                  handleSelectVariant={handleSelectVariant}
                />
              </div>
            </div>
            <div className="my-12 sm:mb-0 space-4 justify-center">
              <ProductTabs product={product?.product} />
            </div>
          </div>
          {product?.relatedProducts?.length > 0 && (
            <ProductForSale
              products={product?.relatedProducts}
              setProduct={setProduct}
              heading={"Related Products"}
              relatedProductPage={true}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProductCard;
