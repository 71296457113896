import React from "react";
import { Link } from "react-router-dom";

const StyleSection = () => {
  const categories = [
    {
      title: "Top Stories",
      items: [
        { name: "Television", link: "/product-list?sub-category-id=670377996a2a6cb98dc04dc4&page=1" },
        { name: "Kitchen Appliances", link: "/product-list?category=Kitchen-Appliances&category_id=66b1e1365472ab860dac9d2a&page=1" },
        { name: "Mobiles", link: "/product-list?category=Mobiles&category_id=66b1de805472ab860dac9d1c&page=1" },
        { name: "Personal Care", link: "/product-list?category=Personal-Care&category_id=66b1df7b5472ab860dac9d22&page=1" },
        { name: "Daily Deal", link: "/product-list?category=Daliy-deal&category_id=66b7361d04044857c3f2b2b9&page=1" },
        { name: "Hot Deals", link: "/product-list?category=Hot-deals&category_id=672dd6c82e201e6f17c5ef19&page=1" },
        { name: "Clearance Sale", link: "/product-list?category=Clearance-Sale&category_id=66e15430a3b1fac4854a1e9e&page=1" },
        { name: "Fan", link: "/product?name=1200mm-Fan-Usha-Striker-One-R-CF-Spanish-Green&product_id=66c9b5b7ad0fde84ea1a790d" },
        { name: "Accessories", link: "/product-list?category=Accessories&category_id=66e1548ca3b1fac4854a1eaf&page=1" },
        { name: "Audio", link: "/product-list?category=Audio&category_id=67135ed9979bc6cd210b0a6b&page=1" },
        { name: "Air Conditioner", link: "/product-list?sub-category-id=66b3023377bbedb89846e6b5&page=1" },
        { name: "Refrigerator", link: "/product-list?sub-category-id=66b3066977bbedb89846e6bd&page=1" },
        { name: "Washing Machine", link: "/product-list?sub-category-id=66b31d08b8d58b966e370d71&page=1" },
        { name: "Geyser", link: "/product-list?sub-category-id=67124547afac4f4473b54e67&page=1" },
        { name: "Air Purifiers", link: "/product-list?sub-category-id=67173a382ba5381a5cd4a4e9&page=1" },
        { name: "Vacuum Cleaner", link: "/product-list?sub-category-id=674ac694349cf1b4f7d2353f&page=1" },
        { name: "Room Heater", link: "/product-list?sub-category-id=67517aeb2efcc2453048abfe&page=1r" },
        { name: "Mixer Grinder", link: "/product-list?sub-category-id=66b32c39b8d58b966e37125f&page=1" },
        { name: "Toaster", link: "/product-list?sub-category-id=66b32fedb8d58b966e37133e&page=1" },
        { name: "Electric Kettle", link: "/product-list?sub-category-id=66b33283b8d58b966e3713a2&page=1" },
        { name: "Hand Blender", link: "/product-list?sub-category-id=66b33c3ab8d58b966e3713cd&page=1" },
        { name: "Nutri Blender", link: "/product-list?sub-category-id=66b33d1bb8d58b966e3713d1&page=1" },
        { name: "Air Fryer", link: "/product-list?sub-category-id=66b36a2f04b4b470f4f7408f&page=1" },
        { name: "Slow Cooker", link: "/product-list?sub-category-id=66b4618304b4b470f4f74310&page=1" },
        { name: "Water Dispenser", link: "/product-list?sub-category-id=66b4643404b4b470f4f74331&page=1" },
        { name: "Microwave Ovens", link: "/product-list?sub-category-id=66b4720304b4b470f4f7440b&page=1" },
        { name: "Water Purifiers", link: "/product-list?sub-category-id=6703b86a94d50edb56735524&page=1" },
        { name: "Induction Stove", link: "/product-list?sub-category-id=6704d27709afb05e5f57795a&page=1" },
        { name: "Kitchen Tools", link: "/product-list?sub-category-id=670f9ab11a10a195da9fae2d&page=1" },
        { name: "Chimney", link: "/product-list?sub-category-id=67123feaafac4f4473b53093&page=1" },
        { name: "Gas Stove & Hobs", link: "/product-list?sub-category-id=6756e26f247fc3bf4873befb&page=1" },
        { name: "Iron", link: "/product-list?sub-category-id=66b459d304b4b470f4f741f5&page=1" },
        { name: "Small Personal Care", link: "/product-list?sub-category-id=6704dbd93fc877b0b4e92c4f&page=1" },
        { name: "Watch", link: "/product-list?sub-category-id=670676d9ed6b987d03197b68&page=1" },
        { name: "Headphones", link: "/product-list?sub-category-id=67067e7d0c095ef7c895c3ee&page=1" },
        { name: "Speaker", link: "/product-list?sub-category-id=67163750a8f1c41a20ff25c3&page=1" },
 
      ].sort((a, b) => a.name.localeCompare(b.name)), // Sort items alphabetically
    },
  ];

  return (
    <div className="">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-50 to-blue-100">
        <div className="w-full mx-auto px-4 py-8">
          <div className="text-center max-w-4xl mx-auto sm:text-left">
            <h1 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">
              CayroShop: Your Go-To Online Electronics Store in Delhi NCR
            </h1>
            <h3 className="text-gray-600 text-sm md:text-base leading-relaxed">
              CayroShop.com is the Best electronic shopping hub in Gurugram, where a wide variety 
              of affordable electronics are available, such as smart LED TVs, energy-efficient 
              washing machines, and more.
            </h3>
          </div>
        </div>
      </div>

      {/* Categories Section */}
      <div className="w-full shadow-sm mt-4">
        {categories.map((category, index) => (
          <div key={index} className="p-6">
            <h2 className="text-[20px]  font-medium  mb-4 pl-4 font-semibold">
              {category.title}
            </h2>
            <div className="flex w-full flex-wrap">
              {category.items.map((item, idx) => (
                <div
                  key={idx}
                  className="hover:bg-blue-50 rounded-lg transition-colors duration-200"
                >
                  <Link
                    to={item.link}
                    className="block p-3 text-sm text-gray-600 hover:text-blue-600"
                  >
                    <div className="text-left">
                      {item.name}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* Additional Info */}
        <div className="p-6  text-center border-t">
          <h2 className="text-sm mb-4 font-semibold sm:text-left">
            Best online electronics store in Delhi NCR, bestowing India with 
            budget-friendly electronics and fast and reliable delivery of electronics in Gurugram.
          </h2>
          {/* <button className="bg-blue-600 hover:bg-blue-700 text-white font-medium px-6 py-2 rounded-lg text-sm transition-colors duration-200">
            Shop Now!
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default StyleSection;