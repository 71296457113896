import React, { useState } from "react";
import { MdCancel } from "react-icons/md";
import { PostRequest } from "../../../api/Request";
import { toast } from "react-toastify";

// Enum for Time Options
enum PreferredTime {
  "10AM_12PM" = "10AM - 12PM",
  "12PM_2PM" = "12PM - 2PM",
  "2PM_4PM" = "2PM - 4PM",
  "4PM_6PM" = "4PM - 6PM",
}

const ConnectToStore: React.FC = () => {
  const [connectStore, setConnectStore] = useState<boolean>(true);
  const [options, setOptions] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  const [preferredTime, setPreferredTime] = useState<string>("");

  const handleOptions = () => {
    setOptions(!options);
  };

  const closeConnectedStore = () => {
    setConnectStore(false);
  };

  // Validate preferredTime using Enum
  const isValidTime = (time: string): boolean => {
    return Object.values(PreferredTime).includes(time as PreferredTime);
  };

  const handleSubmit = async () => {
    if (!name || !phoneNumber || !preferredTime) {
      alert("Please fill out all required fields.");
      return;
    }

    // Regular expression for phone number validation
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      alert("Please enter a valid phone number.");
      return;
    }

    // Validate preferredTime using Enum
    if (!isValidTime(preferredTime)) {
      alert("Please select a valid time.");
      return;
    }

    const data = {
      name,
      phoneNumber,
      remark,
      preferredTime,
    };

    // Log the form data to the console
    console.log("data print in console ", data);

    try {
      const response = await PostRequest("/store-connection", data);
      if (response.status === 201) {
        toast.success("Your query has been sent successfully.");
        closeConnectedStore(); // Close the form after successful API call
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const handleTimeSelection = (time: string) => {
    setPreferredTime(time);
    setOptions(false); // Close the time selection options after selection
  };

  return (
    <div>
      {connectStore && (
        <div className="overflow-auto z-10 bg-white border shadow-lg rounded-md p-2  mx-2">
          <h3
            className="text-[18px] font-semibold text-end text-black justify-end flex cursor-pointer"
            onClick={closeConnectedStore}
          >
            <MdCancel />
          </h3>

          <div className="h-12 w-12 rounded-full mx-auto bg-gray-500 flex justify-center items-center text-white font-semibold text-[20px]">
            C
          </div>
          <div className="text-center">
            <p className="text-[18px] text-black text-center font-semibold">
              Cayro Shop
            </p>
            {/* <p className="text-[12px] font-medium text-blue-500">
              Store Manager
            </p> */}
          </div>

          <div className="m-2">
            <div className="flex flex-col">
              <label
                htmlFor="name"
                className="text-black text-[13px] font-medium"
              >
                Name
              </label>
              <input
                type="text"
                placeholder="Enter Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="rounded-md shadow-md border-2 mt-1 outline-none px-2 py-1.5 font-medium text-black opacity-50"
              />
            </div>

            <div className="flex flex-col mt-2">
              <label
                htmlFor="phoneNumber"
                className="text-black text-[13px] font-medium"
              >
                Phone Number
              </label>
              <div className="flex gap-3 items-center relative justify-center">
                <p className="absolute left-3 top-1/2 transform -translate-y-1/2 text-black opacity-50 font-medium mt-[3px]">
                  +91
                </p>
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="rounded-md shadow-md w-full border-2 mt-1 outline-none px-12 py-1.5 font-medium text-black opacity-50"
                />
              </div>
            </div>

            <div className="flex flex-col mt-2">
              <label
                htmlFor="remark"
                className="text-black text-[13px] font-medium"
              >
                Query
              </label>
              <input
                type="text"
                placeholder="Write Your Query"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                className="rounded-md shadow-md border-2 mt-1 outline-none px-2 py-1.5 font-medium text-black opacity-50"
              />
            </div>

            <div className="flex flex-col mt-2">
              <label
                htmlFor="preferredTime"
                className="text-black text-sm font-medium"
              >
                Do you have a preferred time for us to call you?
              </label>
              <input
                type="text"
                placeholder="Choose a Time"
                value={preferredTime}
                onClick={handleOptions}
                onChange={(e) => setPreferredTime(e.target.value)}
                className="rounded-md shadow-md border-2 mt-1 outline-none px-2 py-1.5 font-semibold text-black opacity-50"
              />
              {options && (
                <div className="flex gap-2 flex-col text-black">
                  {Object.values(PreferredTime).map((time) => (
                    <div
                      key={time}
                      className="flex gap-2 items-center cursor-pointer mt-2 hover:rounded-md"
                      onClick={() => handleTimeSelection(time)}
                    >
                      <p className="text-sm font-medium text-black">{time}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <button
              className="flex justify-center items-center px-4 py-1.5 text-white font-semibold mx-auto mt-4 bg-blue-500 text-sm rounded-md"
              onClick={handleSubmit}
            >
              Request A Call Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectToStore;
