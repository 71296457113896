import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom"; // Import useLocation
interface MetaInformation {
  metaInfoTitle?: string;
  metaInfoDescription?: string;
  metaInfoKeyword?: string | string[];
}


// In utils/Helmet.ts
export const RenderHelmetForCat = ({ metaInformations }: { metaInformations: MetaInformation }) => {
  const location = useLocation();
  const currentPath = location.pathname + location.search;

  return (
    <>
    <Helmet>
    {metaInformations?.metaInfoTitle && (
        <title>{metaInformations?.metaInfoTitle}</title>
      )}
      {metaInformations?.metaInfoDescription && (
        <meta name="description" content={metaInformations?.metaInfoDescription} />
      )}
      {metaInformations?.metaInfoKeyword && (
        <meta
          name="keywords"
          content={Array.isArray(metaInformations?.metaInfoKeyword)
            ? metaInformations?.metaInfoKeyword?.join(",")
            : metaInformations?.metaInfoKeyword}
        />
      )}
      <link rel="canonical" href={`https://www.cayroshop.com${currentPath}`} />
    </Helmet>
    
    </>
  );
};
