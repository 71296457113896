import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";

interface FiltersProps {
  filters: {
    minPrice?: number;
    maxPrice?: number;
    [key: string]: any; // To handle other filter categories dynamically
  };
}

const Filters: React.FC<FiltersProps> = ({ filters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { minPrice = 0, maxPrice = 1000, ...otherFilters } = filters;
  const [isInitialReload, setIsInitialReload] = useState(false);

  const [priceRange, setPriceRange] = useState<number[]>([
    parseInt(searchParams.get("minPrice") || `${minPrice}`, 10),
    parseInt(searchParams.get("maxPrice") || `${maxPrice}`, 10),
  ]);
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [openCategories, setOpenCategories] = useState<{ [key: string]: boolean }>({});
  
  // Update query params when price range changes
  const handlePriceChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      setPriceRange(value);
      searchParams.set("minPrice", value[0].toString());
      searchParams.set("maxPrice", value[1].toString());
      setSearchParams(searchParams);
    }
  };

  // Handle checkbox changes for other filters
  const handleCheckboxChange = (category: string, value: string, checked: boolean, id?: string) => {
    const updatedFilters = { ...selectedFilters };
  
    // Map "Brands" to "brand" in the query params
    const queryCategory = category.toLowerCase() === "brands" ? "brand" : category;
  
    if (checked) {
      updatedFilters[category] = [...(updatedFilters[category] || []), id || value];
    } else {
      updatedFilters[category] = (updatedFilters[category] || []).filter((v) => v !== (id || value));
    }
  
    setSelectedFilters(updatedFilters);
  
    // Update query parameters
    searchParams.delete(queryCategory); // Remove existing keys for this category
    updatedFilters[category]?.forEach((filterValue) =>
      searchParams.append(queryCategory, filterValue) // Singular key (e.g., brand)
    );
  
    setSearchParams(searchParams);
  };
  

  const toggleCategory = (category: string) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };
  useEffect(() => {
    // Detect a manual page reload
    const navigationEntry = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;

    if (navigationEntry?.type === "reload" && !isInitialReload) {
      setSearchParams({});
      setIsInitialReload(true); // Prevent repeated resets
    }
  }, [setSearchParams, isInitialReload]);
  return (
    <div className="p-4 rounded w-80 bg-white">
      <h2 className="font-bold text-xl mb-4">Filters</h2>

      {/* Price Range with Slider */}
      <div className="mb-6">
        <h3 className="font-semibold text-sm mb-2 text-gray-400">Price Range</h3>
        <div className="flex justify-between text-sm text-gray-700 mb-4">
          <span>Rs {priceRange[0]}</span>
          <span>Rs {priceRange[1]}</span>
        </div>
        <Slider
          range
          min={minPrice}
          max={maxPrice}
          value={priceRange}
          onChange={handlePriceChange}
          trackStyle={[{ backgroundColor: "#3C82F6" }]}
          handleStyle={[
            { borderColor: "#3C82F6", backgroundColor: "#3C82F6" },
            { borderColor: "#3C82F6", backgroundColor: "#3C82F6" },
          ]}
          railStyle={{ backgroundColor: "#3C82F6" }}
        />
      </div>

      {/* Render Other Filters */}
      {Object.keys(otherFilters).length > 0 ? (
        Object.keys(otherFilters).map((filterCategory, idx) => (
          <div key={idx} className="mb-4 border-b pb-2">
            <button
              className="flex items-center justify-between w-full text-left font-semibold text-sm mb-2 capitalize focus:outline-none text-gray-400"
              onClick={() => toggleCategory(filterCategory)}
            >
              {filterCategory}
            </button>
            
              <div className="ml-4">
                {Array.isArray(otherFilters[filterCategory]) &&
                otherFilters[filterCategory].length > 0 ? (
                  otherFilters[filterCategory].map(
                    (filter: { name?: string; _id?: string } | string, subIdx: number) => {
                      const filterName = typeof filter === "string" ? filter : filter.name || "";
                      const filterId = typeof filter === "object" ? filter._id : undefined;
                      const isChecked = selectedFilters[filterCategory]?.includes(filterId || filterName);

                      return (
                        <label
                          key={subIdx}
                          className="block text-gray-700 mb-2 cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            value={filterName}
                            checked={isChecked}
                            onChange={(e) =>
                              handleCheckboxChange(
                                filterCategory,
                                filterName,
                                e.target.checked,
                                filterId
                              )
                            }
                            className="mr-2"
                          />
                          {filterName}
                        </label>
                      );
                    }
                  )
                ) : (
                  <p className="text-gray-500 text-sm italic">No filters available</p>
                )}
              </div>
      
          </div>
        ))
      ) : (
        <p className="text-gray-500 text-center italic">No filters to display</p>
      )}
    </div>
  );
};

export default Filters;
