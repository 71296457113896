import React, { useEffect, useState } from "react";
import { showErrorToast } from "../../utils/toast";
import { GetRequest } from "../../api/Request";
import { Link } from "react-router-dom";

interface Brand {
  _id: string;
  image: {
    url: string;
  };
}

const BrandComponent: React.FC = () => {
  const [allBrand, setAllBrand] = useState<Brand[]>([]);
  const [isHovered, setIsHovered] = useState(false);

  const getAllBrand = async () => {
    try {
      const response = await GetRequest("all-brand");
      if (response.status === 200) {
        setAllBrand(response.data.brands);
      }
    } catch (error) {
      if (error instanceof Error) {
        showErrorToast(error.message);
      } else {
        showErrorToast("An error occurred while fetching brands");
      }
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    getAllBrand();
  }, []);

  // Duplicate the brands array to create a seamless loop
  const duplicatedBrands = [...allBrand, ...allBrand];

  return (
    <div className="p-4 sm:pt-4 mx-auto my-0 overflow-hidden pb-8 sm:pb-0">
      <div className="flex flex-col">
        <h2 className="text-xl md:text-xl font-semibold  flex items-center justify-center gap-2">Trending Brands
        <hr className="flex-grow border-t-2 border-gray-300" />

        </h2>
        <div 
          className={`relative flex mt-4 animate-marquee ${isHovered ? 'paused' : ''}`}
          style={{
            width: 'fit-content',
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {duplicatedBrands.map((brand, index) => (
            <Link
              key={`${brand._id}-${index}`}
              to={`/product-list?brand-id=${brand._id}&page=1`}
              className="flex-shrink-0 flex w-32 sm:w-24 mx-6 sm:mx-3 items-center justify-center bg-white"
            >

              
              <img
                src={brand.image.url}
                alt={`Brand logo ${index + 1}`}
                className="h-20 w-auto object-contain"
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandComponent;