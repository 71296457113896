import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { fetchBlogs } from "../../slice/BlogSlice";

// Define interfaces for type safety
interface BlogImage {
  _id: string;
  publicId: string;
  url: string;
}

interface ExpandedPosts {
  [key: string]: boolean;
}

const Blog: React.FC = () => {
  const [expandedPosts, setExpandedPosts] = useState<ExpandedPosts>({});
  const dispatch = useDispatch<AppDispatch>();
  const { blogs, loading, error } = useSelector((state: RootState) => state.blog);

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  // Function to truncate the description
  const truncateDescription = (description: string, limit: number): { __html: string } => {
    const stripHtml = (html: string): string => {
      const tmp = document.createElement('div');
      tmp.innerHTML = html; // Remove the HTML tags to count words
      return tmp.textContent || tmp.innerText || '';
    };

    const cleanText = stripHtml(description);
    const words = cleanText.split(' ');

    // Truncate the text and keep the HTML in the description
    const truncatedText = words.length > limit ? words.slice(0, limit).join(' ') + '...' : cleanText;

    // Return truncated HTML content
    return {
      __html: description.substring(0, truncatedText.length) + (words.length > limit ? '...' : '')
    };
  };

  // Handle the Read More/Show Less toggle
  const handleReadMore = (blogId: string): void => {
    setExpandedPosts(prev => ({
      ...prev,
      [blogId]: !prev[blogId],
    }));
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-pulse text-blue-500">Loading...</div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">Error: {error}</div>;
  }

  const firstBlog = blogs[0] as any;
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header Section */}
      <div className="pt-20 pb-12 bg-gradient-to-b from-white to-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-3 mt-6">
          <div className="text-center">
            <img
              src="./blog2.png"
              alt="Blog Logo"
              className="h-28 w-60 mx-auto mb-8 smm:h-24 smm:w-52 xs:h-20 xs:w-44"
            />
          </div>
        </div>
      </div>

      <div className="w-full mx-auto px-4 sm:px-3 py-8">
        {/* Featured Post */}
        {firstBlog && (
          <div className="bg-white rounded-xl shadow-sm overflow-hidden mb-16">
            <div className="flex flex-col w-full">
              <div className="w-full relative">
                <img
                  src={firstBlog.images[0]?.url ?? "https://via.placeholder.com/800x600"}
                  alt={firstBlog.title}
                  className="w-full h-[400px] md:h-[300px] sm:h-[250px] object-cover"
                />
                <div className="absolute top-4 left-4 bg-blue-500 text-white px-3 py-1 rounded-full text-sm">
                  Featured
                </div>
              </div>
              <div className="w-full p-8 sm:p-6 xs:p-4">
                <div className="flex items-center gap-4 mb-4">
                  <span className="text-sm text-gray-500">2 weeks ago</span>
                  <span className="w-1 h-1 bg-gray-300 rounded-full"></span>
                  <span className="text-sm text-gray-500">15 Feb 2024</span>
                </div>
                <h2 className="text-2xl sm:text-xl font-bold text-gray-800 mb-4">{firstBlog.title}</h2>
                <div className="text-gray-600 leading-relaxed mb-6 sm:text-sm">

                  <div
                    className="blog-description prose prose-lg max-w-none"
                    dangerouslySetInnerHTML={{ __html: firstBlog.description }} // Render full HTML description
                  />
                </div>
                <button
                  onClick={() => handleReadMore(firstBlog._id)}
                  className="inline-flex items-center px-6 py-3 sm:px-4 sm:py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all"
                >
                  {expandedPosts[firstBlog._id] ? 'Show Less' : 'Read More'}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Blog Grid */}
        <div className="grid grid-cols-3 md:grid-cols-1 gap-8 sm:gap-6 xs:gap-4">
          {blogs.slice(1).map((blog: any) => (
            <div key={blog._id} className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-all">
              <div className="relative">
                <img
                  src={blog.images[0]?.url ?? "https://via.placeholder.com/400x300"}
                  alt={blog.title}
                  className="w-full h-56 sm:h-48 xs:h-40 object-cover"
                />
                <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-black/50 to-transparent"></div>
              </div>
              <div className="p-6 sm:p-4">
                <div className="flex items-center gap-2 mb-3">
                  <span className="text-sm text-gray-500">2 weeks ago</span>
                </div>
                <h3 className="text-xl sm:text-lg font-bold text-gray-800 mb-3">{blog.title}</h3>
                <div className="text-gray-600 mb-4 text-sm sm:text-xs">
                  {expandedPosts[blog._id] ? (
                    <div
                      className="blog-description prose prose-sm max-w-none"
                      dangerouslySetInnerHTML={{ __html: blog.description }}  // Render full HTML description
                    />
                  ) : (
                    <div
                      className="blog-description prose prose-sm max-w-none"
                      dangerouslySetInnerHTML={truncateDescription(blog.description, 20)}  // Render truncated HTML description
                    />
                  )}
                </div>
                <button
                  onClick={() => handleReadMore(blog._id)}
                  className="text-blue-500 hover:text-blue-600 font-medium text-sm"
                >
                  {expandedPosts[blog._id] ? 'Show Less' : 'Read More →'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
