import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addProduct } from "./CartSlice"; // Adjust the import path to your cart slice
import { RootState } from "../store/store";
import { GetRequest, PostRequest } from "../api/Request";
import { max, update } from "lodash";

interface ProductState {
  allProduct: any[]; // Specify that allProduct is an array of Product
  totalPages: number;
  page: number;
  totalItems: number;
  limitForProduct: number;
  isLoading: boolean;
  filters: any;
  selectedFilter: any;
  metaInfo: any;
}

// Define the initial state
const initialState: ProductState = {
  selectedFilter: [],
  allProduct: [],
  totalPages: 0,
  page: 1,
  totalItems: 0,
  limitForProduct: 12,
  isLoading: false,
  filters: {},
  metaInfo: {},
};


// Create the async thunk for fetching products
export const getAllProductAccordingToCategoryOrSubCategoryOrBrand = createAsyncThunk(
  "product/getAllProducts",
  async ({ subCategoryIdForProduct, categoryIdForProduct, brandId, pageForProduct, sortBy, minPrice, maxPrice, ...filters }: any, { getState, rejectWithValue }) => {
    // Initialize the payload structure
    let payload: any = {
      brand: filters.brand, // Directly assign brand filter
      variations: {}, // Initialize variations as an empty object
    };

    // Loop through filters and assign to variations dynamically (excluding 'brand')
    for (const [key, value] of Object.entries(filters)) {
      if (key.toLowerCase() !== "brand") {
        payload.variations[key] = value; // Add all non-brand filters to variations
      }
    }

    // Base URL based on the presence of subCategoryIdForProduct or categoryIdForProduct
    let baseUrl = '';
    if (brandId) {
      baseUrl = `searchproductbyBrand/${brandId}`;
    } else if (subCategoryIdForProduct) {
      baseUrl = `searchproductbySubCategory/${subCategoryIdForProduct}`;
    } else {
      baseUrl = `searchproductbyCategory/${categoryIdForProduct}`;
    }

    // Construct the URL with query parameters
    const queryParams = new URLSearchParams({
      page: String(pageForProduct),
      limit: String(initialState.limitForProduct),
      ...(minPrice && maxPrice ? { minPrice, maxPrice } : {}), // Add minPrice and maxPrice only if they are provided
      ...(sortBy ? { sortBy } : {}),
    });

    // Final URL with query parameters
    const url = `${baseUrl}?${queryParams.toString()}`;

    try {
      const response = await PostRequest(url, payload);

      if (response.status === 200) {
        const cartProducts = (getState() as RootState).cart.products;
        const products =
          response.data.products.docs.length > 0
            ? await response.data.products.docs.map((product: any) => {
                const cartProduct =
                  cartProducts &&
                  cartProducts.find(
                    (cartItem: any) => cartItem._id === product._id
                  );

                return {
                  ...product,
                  quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
                  addToCartStatus: cartProduct ? "Update Cart" : "Add to cart", // Set status based on cart presence
                };
              })
            : [];

        return {
          products,
          totalPages: response.data.products.totalPages,
          totalItems: response.data.products.totalDocs,
          page: pageForProduct,
          metaInfo: response.data.metaInfo,
        };
      } else {
        return rejectWithValue("Failed to fetch products");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchFiltersForCategory = createAsyncThunk(
  "product/fetchFilters",
  async (categoryId: string, { rejectWithValue }) => {
    try {
      const response = await GetRequest(
        `/get-filter-value-by-category/${categoryId}`
      );
      if (response.status === 200) {
        return response.data.filterValuesFormatted;
      } else {
        return rejectWithValue("Failed to fetch filters");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchFiltersForSubCategory = createAsyncThunk(
  "product/fetchFiltersForSubcategory",
  async (subCategoryId: string, { rejectWithValue }) => {
    try {
      const response = await GetRequest(
        `/get-filter-value-by-subcategory/${subCategoryId}`
      );
      if (response.status === 200) {
        return response.data.filterValuesFormatted;
      } else {
        return rejectWithValue("Failed to fetch filters");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const fetchFilterValueForbrand = createAsyncThunk(
  "product/fetchForBrand",
  async (brandId: string, { rejectWithValue }) => {
    try {
      const response = await GetRequest(
        `/get-filter-value-by-brand/${brandId}`
      );
      if (response.status === 200) {
        return response.data.filterValuesFormatted;
      } else {
        return rejectWithValue("Failed to fetch filters");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// export const addToCart = createAsyncThunk(
//   "product/addToCart",
//   async (
//     { product, index }: { product: any; index: number },
//     { dispatch, getState, rejectWithValue }
//   ) => {
//     try {
//       const state = getState() as RootState;
//       const { allProduct } = state.product;
//       const newAllProduct: any = [...allProduct];

//       await dispatch(
//         addProduct({
//           ...product,
//           total: Number(product.salePrice) * product.quantityByUser,
//         })
//       );

//       const updatedProduct = {
//         ...newAllProduct[index],
//         addToCartStatus: "Update Cart",
//       };

//       return { index, updatedProduct };
//     } catch (error) {
//       console.error("Error adding to cart:", error);
//       return rejectWithValue("Failed to add product to cart");
//     }
//   }
// );

// // Create a new action for incrementing the quantity
// export const incrementQuantity = createAsyncThunk(
//   "product/incrementQuantity",
//   async (index: number, { getState }) => {
//     const state = getState() as RootState;
//     const { allProduct } = state.product;
//     const newAllProduct: any = [...allProduct];

//     const updatedProduct = {
//       ...newAllProduct[index],
//       quantityByUser:
//         newAllProduct[index].quantityByUser !== newAllProduct[index].totalStock
//           ? newAllProduct[index].quantityByUser + 1
//           : newAllProduct[index].quantityByUser,
//     };

//     return { index, updatedProduct };
//   }
// );

// // Create a new action for incrementing the quantity
// export const decrementQuantity = createAsyncThunk(
//   "product/incrementQuantity",
//   async (index: number, { getState }) => {
//     const state = getState() as RootState;
//     const { allProduct } = state.product;
//     const newAllProduct: any = [...allProduct];

//     const updatedProduct = {
//       ...newAllProduct[index],
//       quantityByUser:
//         newAllProduct[index].quantityByUser > 1
//           ? newAllProduct[index].quantityByUser - 1
//           : newAllProduct[index].quantityByUser,
//     };

//     return { index, updatedProduct };
//   }
// );

// Create the slice
const allProductSlice = createSlice({
  name: "productAccordingToCategoryOrSubCategoryOrBrand",
  initialState,
  reducers: {
    updateSelectedFilter: (state, action) => {
      const { filterField, filterValue } = action.payload;
      // Find the existing filter object for the given filterField
      const existingFilter = state.selectedFilter.find(
        (filter: any) => filter[filterField]
      );

      if (existingFilter) {
        // If the filterField exists, check if the filterValue is already present
        const valueIndex = existingFilter[filterField].indexOf(filterValue);

        if (valueIndex > -1) {
          // If it exists, remove the filterValue
          existingFilter[filterField].splice(valueIndex, 1);
          console.log(selectedFilter)

          // If the array is empty after removal, remove the filterField object
          if (existingFilter[filterField].length === 0) {
            state.selectedFilter = state.selectedFilter.filter(
              (filter: any) => filter !== existingFilter
            );
          }
        } else {
          // If it doesn't exist, add the filterValue
          existingFilter[filterField].push(filterValue);
        }
      } else {
        // If it doesn't exist, create a new filter entry
        state.selectedFilter.push({ [filterField]: [filterValue] });
      }
    }, // Action to set all products
    setProduct: (state, action) => {
      console.log(action.payload)
      state.allProduct[action.payload.index] = action.payload.product;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllProductAccordingToCategoryOrSubCategoryOrBrand.pending,
        (state) => {
          state.isLoading = true;
        }
      )
      .addCase(
        getAllProductAccordingToCategoryOrSubCategoryOrBrand.fulfilled,
        (state, action) => {
          state.allProduct = action.payload.products;
          state.totalPages = action.payload.totalPages;
          state.totalItems = action.payload.totalItems;
          state.page = action.payload.page;
          state.metaInfo = action.payload.metaInfo;
          state.isLoading = false;

        }
      )
      .addCase(
        getAllProductAccordingToCategoryOrSubCategoryOrBrand.rejected,
        (state) => {
          state.isLoading = false;
        }
      )
      .addCase(fetchFiltersForCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFiltersForCategory.fulfilled, (state, action) => {
        state.filters = action.payload; // Update filters for category
        state.isLoading = false;

      })
      .addCase(fetchFiltersForCategory.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchFiltersForSubCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFiltersForSubCategory.fulfilled, (state, action) => {
        state.filters = action.payload; // Update filters for sub-category
        state.isLoading = false;

      })
      .addCase(fetchFiltersForSubCategory.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchFilterValueForbrand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFilterValueForbrand.fulfilled, (state, action) => {
        state.filters = action.payload; // Update filters for brand
      })
      .addCase(fetchFilterValueForbrand.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

// Export the reducer
export default allProductSlice.reducer;

export const { updateSelectedFilter, setProduct } = allProductSlice.actions;

export const filters = (state: RootState) => state.product.filters;

export const metaInfo = (state: RootState) => state.product.metaInfo;

export const selectedFilter = (state: RootState) =>
  state.product.selectedFilter;

export const totalItemForProduct = (state: RootState) => {
  return state.product.totalItems;
};
export const totalPageForProduct = (state: RootState) => {
  return state.product.totalPages;
};
export const product = (state: RootState) => {
  return state.product.allProduct;
};

export const isLoadingInProduct = (state: RootState) => {
  return state.product.isLoading;
};

export const page = (state: RootState) => {
  return state.product.page;
};

export const isLoadingForProduct = (state: RootState) => {
  return state.product.isLoading;
};
