import React from "react";
import { useSearchParams } from "react-router-dom";

interface PaginationProps {
  totalPages: number;
  currentPage: number; // Current page from props
  onPageChange: (page: number) => void; // Callback for page change
}

const Pagination: React.FC<PaginationProps> = ({ totalPages, currentPage, onPageChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageClick = (page: number) => {
    if (page > 0 && page <= totalPages) {
      // Clone the current search parameters
      const newParams = new URLSearchParams(searchParams.toString());
      newParams.set("page", page.toString()); // Update only the 'page' parameter

      // Set the updated parameters back to the URL
      setSearchParams(newParams);

      // Notify parent component of the page change
      onPageChange(page);
    }
  };

  return (
    <div className="flex items-center justify-center mt-6">
      {[...Array(totalPages)].map((_, index) => {
        const page = index + 1;
        return (
          <button
            key={page}
            className={`flex items-center justify-center px-3 h-8 leading-tight border text-sm font-medium transition-all ${
              currentPage === page
                ? "bg-primary text-white border-gray-300"
                : "bg-white text-black border-gray-300"
            }`}
            onClick={() => handlePageClick(page)}
          >
            {page}
          </button>
        );
      })}
    </div>
  );
};

export default Pagination;
