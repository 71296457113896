import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./index.css";
import Home from "./page/main/Home";
import ConnectToStore from "./page/productDetail/components/ConnectToStore";
import CallNow from "../src/loti/callNow.json";
import Header from "./page/header/Header";
import "react-toastify/dist/ReactToastify.css";
import ProductCard from "./page/productDetail/ProductCard";
import ShoppingCart from "./page/cart/Cart";
import mainLoader from "./loti/mainLoader.json";
import { ReviewComponent } from "./page/reviews/Review";
import SubCategory from "./page/header/SubCategory";
import ProductListing from "./page/productListing/ProductListing";
import { useMediaQuery } from "@react-hook/media-query";
import Wishlist from "./page/wishlist/Wishlist";
import Contact from "./page/contact/Contact";
import About from "./page/about/about";
import TermsAndConditions from "./page/termsAndCondition/termsAndCondition";
import ReturnAndExchange from "./page/returnAndExchange/ReturnAndExchange";
import Footer from "./page/main/Footer";
import { useEffect, useState } from "react";
import { GetRequest, PostRequest } from "./api/Request";
import { showErrorToast } from "./utils/toast";
import MobileCart from "./page/cart/MobileCart";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "./slice/UserSlice";
import Lottie from "lottie-react";
import Profile from "./page/userSetting/userDetail/Profile";
import OrderList from "./page/userSetting/userDetail/orderList";
import { AxiosError } from "axios";
import { UserInfo } from "./slice/UserSlice";
import AddressList from "./page/userSetting/userDetail/AddressList";
import PrivacyPolicy from "./page/privacyPolicy/PrivacyPolicy";
import ReturnRefundPolicy from "./page/returnAndRefundPolicy/ReturnAndRefundPolicy";
import ShippingDeliveryPolicy from "./page/shippingAndDelivery/ShippingAndDeliveryPolicy";
import Checkout from "./page/checkout/Checkout";
import { setCartLength } from "./slice/CartSlice";
import Compare from "./common/compare/Compare";
import { compareProducts } from "./slice/CompareProductsSlice";
import NotFoundPage from "./page/notFoundPage/NotFoundPage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoginWithOtp from "./page/login/LoginWithOtp";
import Sitemaps from "./page/sitemaps/Sitemaps";
import { requestForToken } from "./firebase/firebase";
import FAQs from "./page/faqs/FAQs";
import SearchPage from "./page/search/Search";
import RedirectHandler from "./RedirectHandler";
import EventPage from "./page/eventPage/EventPage";
import Blog from "./page/blog/Blog";

function App() {
  const location = useLocation();
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
 
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [connectStore, setConnectStore] = useState<boolean>(false);
  const [renderedComponent, setRenderedComponent] = useState<JSX.Element>(
    isMobile ? <MobileCart /> : <ShoppingCart />
  );
  const userDetail = useSelector(UserInfo);
  const [isLoadingForCategory, setIsLoadingForCategory] =
    useState<boolean>(true);
  const compareTheseProducts = useSelector(compareProducts);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allCategory, setAllCategory] = useState<any>([]);
  const [subCategories, setSubCategories] = useState<any>([]);
  // State to manage loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [data, setData] = useState<any>(null);
  const hadleConnectStore = () => {
    setConnectStore(!connectStore);
  };

  // Function to fetch user profile
  const fetchUserProfile = async () => {
    try {
      const response = await GetRequest("/user-profile");
      setData(response.data);
    } catch (err) {
      localStorage.removeItem("userToken");
      setError(err as AxiosError);
    } finally {
      setLoading(false);
    }
  };
  const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("firebase-messaging-sw.js")
        .then(function (registration) {
          // eslint-disable-next-line no-console
          return registration.scope;
        })
        .catch(function (err) {
          return err;
        });
    }
  };
  registerServiceWorker();
  const saveCartFromLocalToApi = async () => {
    try {
      // Retrieve the cart from local storage
      const localCart = JSON.parse(localStorage.getItem("cart") || "[]");

      if (localCart.length > 0) {
        let totalCartItem = 0;
        // Iterate over the local cart items and send them one by one to the API
        for (const item of localCart) {
          const response = await PostRequest("/addItemToCart", {
            productId: item._id,
            quantity: item.quantityByUser,
          });

          if (response.status == 200) {
            totalCartItem = response.data.count;
          }
        }

        // Optionally, clear the local cart after saving
        localStorage.removeItem("cart");

        // Dispatch action to update the cart length in Redux
        dispatch(setCartLength(totalCartItem));
      }
    } catch (error) {
      console.error("Error saving cart to API:", error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      fetchUserProfile();
      saveCartFromLocalToApi();
      requestForToken();
    }
  }, []);

  // Use another useEffect to dispatch user data when data is fetched
  useEffect(() => {
    if (data) {
      dispatch(
        setUser({
          _id: data.profile._id || "",
          name: data?.profile?.username || "",
          number: data?.profile?.number || "",
          gender: data?.profile?.gender || "",
          email: data?.profile?.email || "",
          addresses: data?.profile.addresses || "",
          userIsLoggedIn: true,
        })
      );
    }
  }, [data, dispatch]);

  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    setRenderedComponent(isMobile ? <MobileCart /> : <ShoppingCart />);
  }, [isMobile]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const getAllCategory = async () => {
    setIsLoadingForCategory(() => true);
    try {
      let response = await GetRequest("/getAllproduct-categories");
      if (response.status === 200) {
        setAllCategory(() => response?.data);
        setSubCategories((prevState: any) => {
          return response?.data?.flatMap(
            (category: any) => category?.subCategories
          );
        });
      }
    } catch (error: any) {
      showErrorToast(error?.response?.data?.message);
    }
    setIsLoadingForCategory(() => false);
  };

  // useEffect(() => {
  //   if (location.pathname.includes("home")) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Set latitude and longitude when retrieved
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error("Error getting location: ", error);

          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };


    // Get user location when component mounts
    getUserLocation();
  }, [latitude, longitude]);


  return loading ? (
    <div className="h-[100vh] flex justify-center items-center ">
      <LazyLoadImage
        effect="blur"
        src={"https://flagicons.lipis.dev/flags/4x3/in.svg"}
        alt={"indian-flag"}
        className="inline-block h-4 mr-1"
        // Optional effect for loading transition
      />
      {/* <Helmet>
        <title>cayroshop.com</title> */}
      {/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" /> */}
      {/* </Helmet> */}
      <Lottie
        animationData={mainLoader}
        loop={true}
        style={{
          width: "10rem",
          height: "10rem",
        }}
        className="w-[20rem] h-[20rem] sm:w-[12rem] sm:h-[12rem]"
      />
    </div>
  ) : (
    <>
      {compareTheseProducts?.length > 0 &&
        !location.pathname.includes("comparison") && <Compare />}
      <Header
        allCategory={allCategory}
        isLoadingForCategory={isLoadingForCategory}
      />
      <Routes>
        {/* <Route path="/review" Component={Review} /> */}

        <Route path="/product" Component={ProductCard} />

        <Route path="/review" Component={ReviewComponent} />
        <Route path="/privacy-policy" Component={PrivacyPolicy} />
        <Route path="/return-and-refund" Component={ReturnRefundPolicy} />

        <Route
          path="/shipping-and-delivery"
          Component={ShippingDeliveryPolicy}
        />
        <Route path="/checkout" Component={Checkout} />
        <Route
          path="/"
          element={
            <Home allCategory={allCategory} subCategories={subCategories} />
          }
        />
        {/* <Route path="/republic-day-sale" element={<EventPage allCategory={allCategory}/>}/> */}
        <Route path="/list-of-address" Component={AddressList} />
        {/* <Route path="/login" Component={Login} /> */}
        <Route path="/login-by-otp" Component={LoginWithOtp} />
        {/* <Route path="/forget-password" Component={ForgetPassword} /> */}

        {/* <Route path="/register" Component={Register} /> */}
        <Route path="/subcategory" Component={SubCategory} />
        <Route path="/profile" Component={Profile} />
        <Route path="/order-info" Component={OrderList} />
        <Route path="/wishlist" Component={Wishlist} />
        <Route path="/cart" element={renderedComponent} />
        <Route path="/search/:searchText" element={<SearchPage lat={latitude} lng={longitude}/>} />
        <Route path="/contact" Component={Contact}></Route>
        <Route path="/about" Component={About}></Route>
        <Route path="/sitemap" Component={Sitemaps} />
        <Route path="/faqs" Component={FAQs} />
        <Route
          path="/return-and-exchange"
          Component={ReturnAndExchange}
        ></Route>
        <Route
          path="/terms-and-condition"
          Component={TermsAndConditions}
        ></Route>
        <Route path="/product-list" Component={ProductListing} />
        <Route path="/blogs" Component={Blog} /> 

        <Route path="*" Component={NotFoundPage}/>
      </Routes>
      <Footer />
      <div className="relative">
        <div className="bottom-0 right-0 fixed z-10">
          <div onClick={hadleConnectStore} className="cursor-pointer">
            <Lottie animationData={CallNow} className="w-20 h-20" />
          </div>
        </div>
        {connectStore && (
          <div className="fixed right-2  bottom-10 z-20 ">
            <ConnectToStore />
          </div>
        )}
      </div>
    </>
  );
}

export default App;
