import { Link } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";
import { useEffect, useRef, useState } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

// interface CategoryProps {
//   setShowCategory: (show: boolean) => void;
//   allCategory: any;
//   isLoadingForCategory: boolean;
// }
// const Category: React.FC<CategoryProps> = ({
//   setShowCategory,
//   allCategory,
//   isLoadingForCategory,
// }) => {

{
  /* arrow in triangale */
}

{
  /* <div
        className="w-0 h-0 absolute top-[-17px] left-[20%]
  border-l-[10px] border-l-transparent
  border-b-[17px] border-b-white
  border-r-[11px] border-r-transparent"
      ></div> */
}

const Category = (props: any) => {
  let { allCategory, setShowCategory, isLoadingForCategory } = props;
  const categoryRef = useRef<any>(null);
  const [selectedIndexOfCategory, setSelectedIndexOfCategory] =
    useState<number>(0);

  const handleClickOutside = (event: { target: any }) => {
    if (categoryRef.current && !categoryRef.current.contains(event.target)) {
      setShowCategory(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section
      ref={categoryRef}
      style={{
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
      className="rounded-xl relative bg-white"
    >
      <div className="flex mx-[1rem] w-[100%] no-scrollbar">
        <div className="py-[1rem]">
          <div className="flex flex-wrap">
            <div className="px-4">
              <div className="w-full overflow-scroll  max-h-[500px] no-scrollbar">
                {allCategory.length > 0 &&
                  allCategory.map((category: any, index: number) => (
                    <div
                      key={index}
                      className="mb-[0.5rem] flex flex-col cursor-pointer no-scrollbar text-black hover:text-primary"
                    >
                      <div className="flex items-center mb-4">
                        {/* <img 
                          src={`https://cdn-icons-png.freepik.com/512/6265/6265352.png`}
                          alt={category.category}
                          className="w-6 h-6 mr-2"
                        /> */}
                        <Link
                          to={`product-list?category=${category.category.replace(
                            /\s+/g,
                            "-"
                          )}&category_id=${category._id}&page=1`}
                          className="text-sm font-semibold"
                          onClick={() => setShowCategory(false)}
                          onMouseEnter={() => {
                            setSelectedIndexOfCategory(() => index);
                          }}
                        >
                          {category.category}
                          {category.subCategories.length > 0 && (
                            <RiArrowRightSLine className="inline ml-1" />
                          )}
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {selectedIndexOfCategory === 0 ||
              (selectedIndexOfCategory &&
                allCategory[selectedIndexOfCategory].subCategories.length >
                  0 && <div className="w-px bg-gray-300 mx-2"></div>)}

            <div className="max-w-[170px]">
              <ul className="text-sm mb-2">
                {(selectedIndexOfCategory === 0 || selectedIndexOfCategory) &&
                  allCategory[selectedIndexOfCategory].subCategories.map(
                    (subcat: any, subcatIndex: number) => (
                      <li
                        className="text-md mb-2 cursor-pointer text-black hover:text-primary"
                        key={subcatIndex}
                      >
                        <Link
                          onClick={() => setShowCategory(false)}
                          to={`product-list?sub-category-id=${subcat._id}&page=1`}
                        >
                          {subcat.name} 
                        </Link>
                      </li>
                    )
                  )}
              </ul>
            </div>
            {/* <div className="w-px bg-gray-300 mx-4"></div> */}
            <div className="px-2">
              {/* <div className="w-full flex justify-center items-center"> */}
              {/* {selectedIndexOfCategory && (
                <img 
                  // src={allCategory[selectedIndexOfCategory]?.image}
                  src={
                    "https://imgs.search.brave.com/-Wx54F982tUiGXrrHMN3EOL9zmIjTZpQhYXkAydQloE/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly90My5m/dGNkbi5uZXQvanBn/LzA0LzYyLzkxLzk4/LzM2MF9GXzQ2Mjkx/OTg3OV9wcXVocXFS/ajBFenpyWENQYk03/bEVuUWJQZ0t4MUJy/di5qcGc"
                  }
                  alt={allCategory[selectedIndexOfCategory]?.category}
                  className="rounded-lg object-cover w-[300px] h-[300px]"
                  decoding="async"
                />
              )} */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Category;
