import { useEffect, useRef, useState } from "react";
import { GetRequest } from "../../api/Request";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BestSellingProducts = () => {
    const [bestSelling, setBestSelling] = useState([]);
    const containerRef = useRef<any>(null);

    const gellAllBestSelling = async () => {
        try {
            const response = await GetRequest("getAllProducts?newLaunches=true");
            if (response.status === 200) {
                setBestSelling(response.data.products.docs);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await gellAllBestSelling();
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);



    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: -400, // Adjust the scroll distance as needed
                behavior: "smooth",
            });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: 400, // Adjust the scroll distance as needed
                behavior: "smooth",
            });
        }
    };

    const truncateName = (name: string, length = 20) => {
        return name.length > length ? `${name.slice(0, length)}...` : name;
    };

    return (
        <div className="p-4">
            <h1 className="text-3xl md:text-xl font-semibold flex items-center justify-center gap-2">
                Best Selling Products
                <hr className="flex-grow border-t-2 border-gray-300" />
            </h1>

            <div className="sm:pt-[0.5rem] mx-auto no-scrollbar overflow-x-auto scrollbar-thin scrollbar-thumb-rounded relative">
                <button
                    style={{
                        boxShadow:
                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}
                    onClick={scrollRight}
                    className="absolute right-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px] top-[50%] text-black transform translate-y-[-50%] z-10"
                    aria-label="Scroll Right"
                >
                    <MdOutlineKeyboardArrowRight />
                </button>
                <div
                    ref={containerRef}
                    className="flex p-2 md:p-0 overflow-x-auto no-scrollbar flex-row"
                    style={{ overflowX: "scroll" }}
                >
                    {bestSelling?.length > 0 &&
                        bestSelling?.map((product: any, index: number) => (
                            <div
                                className="item flex-none m-[1rem] bg-white sm:basis-[35%] sm:m-[0.4rem] basis-[20%] flex-shrink-0 p-2 sm:p-[0.5rem] transform transition-transform duration-300 ease-in-out relative rounded-lg flex flex-col justify-between"
                                style={{
                                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                }}
                                key={index}
                            >
                                <Link
                                    to={`/product?name=${product.productName
                                        .replace(/[^a-zA-Z0-9]/g, "-")
                                        .replace(/-+/g, "-")
                                        .toLowerCase()}&product_id=${product._id || product.id}`}
                                    className="flex mt-[0.9rem] justify-center items-center"
                                >
                                    <LazyLoadImage
                                        effect="blur"
                                        src={product?.images[0]?.url}
                                        alt={`Carousel item ${index + 1}`}
                                        className="w-[120px] h-[120px] !flex justify-center items-center sm:h-[100px] object-contain rounded-lg transition-transform duration-300 transform hover:scale-105"
                                    />
                                </Link>

                                <div className="text-center flex-grow">
                                    <h3 className="line-through sm:block text-[0.75rem] ml-[0.3rem] text-gray-500">
                                        ₹{product.regularPrice}
                                    </h3>

                                    <Link
                                        to={`/product?name=${product.productName}&product_id=${product._id}`}
                                    >
                                        {truncateName(product.productName)}
                                    </Link>
                                </div>
                            </div>
                        ))}
                </div>
                <button
                    onClick={scrollLeft}
                    style={{
                        boxShadow:
                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}
                    className="absolute left-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px] top-[50%] text-black transform translate-y-[-50%] z-10"
                    aria-label="Scroll Right"
                >
                    <MdOutlineKeyboardArrowLeft />
                </button>
            </div>
        </div>
    );
};

export default BestSellingProducts;
