const Banner2 = () => {
    return (
        <div className="p-4 w-full">
            <div className="flex items-center justify-between bg-gradient-to-r from-indigo-700 via-indigo-800 to-violet-900 w-full h-full">
                <div className="w-[15%] h-[20vh] relative sm:w-[30%] sm:h-[15vh] md:w-[35%] md:h-[18vh] lg:w-[20%] lg:h-[12vh] xl:w-[18%] xl:h-[14vh] xxl:w-[17%] xxl:h-[16vh] smm:w-[40%] smm:h-[12vh] xs:w-[50%] xs:h-[10vh]">
                    <img
                        src="/newArrivals/mobile_img.svg"
                        className="w-full h-full absolute top-[-10%] object-contain"
                        alt="Mobile"
                    />
                </div>
                <div className="w-[85%] justify-center  text-white sm:w-[70%] sm:text-center md:w-[65%] md:text-left lg:w-[80%] lg:text-left xl:w-[82%] xl:text-left xxl:w-[83%] xxl:text-left smm:w-[60%] smm:text-center xs:w-[100%] xs:text-center">
                    <h1 className="text-2xl font-semibold text-center sm:text-lg smm:text-md xs:text-sm xss:text-xs">
                        Every Order, Every Product - From the Latest Mobiles to Accessories - Delivered Free to Your Door!
                    </h1>

                </div>
            </div>
        </div>
    );
};

export default Banner2;
