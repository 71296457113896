import React, { useState, useEffect } from "react";

const AutoCarouselWithCircle = () => {
  const slides = [
    {
      title: "Best Deal Online on Washing Machine",
      subtitle: "AI Fully-Automatic",
      discount: "UP TO 10% OFF",
      image: "https://www.pngarts.com/files/3/Top-Loading-Washing-Machine-PNG-Transparent-Image.png",
    },
    {
      title: "Top Offer on Refrigerators",
      subtitle: "Smart Cooling",
      discount: "UP TO 20% OFF",
      image: "https://pngimg.com/d/refrigerator_PNG101546.png",
    },
    {
      title: "Get the Best Offers on Microwaves",
      subtitle: "Smart & Compact",
      discount: "UP TO 15% OFF",
      image: "https://pngimg.com/d/microwave_PNG15732.png",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [slides.length]);

  return (
    <div className="w-full h-80 p-4">
      <div className="relative w-full h-full overflow-hidden bg-indigo-950 z-11 p-4 rounded-[20px]">
        {/* Circle Background */}
        <div className="absolute top-[-10%] right-7 transform -translate-y-1/2 w-80 h-80 rounded-full bg-gray-500/20 z-0"></div>
        <div className="absolute top-[-10%] right-0 transform -translate-y-1/2 w-96 h-96 rounded-full border border-gray-600 z-0"></div>

        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 flex items-center justify-between px-10 transition-transform duration-700 ${
              currentIndex === index ? "translate-x-0" : "translate-x-full"
            }`}
          >
            {/* Text Content */}
            <div className="flex flex-col space-y-2 text-white">
              <h2 className="text-lg sm:text-base xs:text-sm font-semibold uppercase">{slide.title}</h2>
              <h1 className="text-4xl sm:text-3xl xs:text-2xl font-bold">{slide.subtitle}</h1>
              <p className="text-xl sm:text-lg xs:text-base">{slide.discount}</p>
            </div>

            {/* Product Image */}
            <div className="relative z-10">
              <img
                src={slide.image}
                alt={slide.subtitle}
                className="w-64 sm:w-48 xs:w-40 h-auto object-contain"
              />
            </div>
          </div>
        ))}

        {/* Navigation Dots */}
        <div className="absolute bottom-4 left-20 transform -translate-x-1/2 flex space-x-2">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`h-2 transition-all duration-300 rounded-full cursor-pointer ${
                currentIndex === index ? "w-6 bg-white" : "w-2 bg-gray-400 hover:bg-gray-300"
              }`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AutoCarouselWithCircle;
