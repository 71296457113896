import { GetRequest, PostRequest } from "../api/Request";
import { addProduct, setCartLength } from "../slice/CartSlice";
import { setProduct } from "../slice/ProductSlice";
import { setFilters, setLoadingFilters, setLoadingProducts, setPage, setSearchProducts } from "../slice/SearchSlice";
import { AppDispatch } from "../store/store";
import { showErrorToast, showInfoToast, showSuccessToast } from "../utils/toast";



export const asyncFetchfilters = (searchText: string) => async (dispatch: any) => {
    try {
        dispatch(setLoadingFilters(true))
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        const response = await GetRequest(`/searchFilterValue/${searchText}`)
        dispatch(setFilters(response.data))
        dispatch(setLoadingFilters(false))

    } catch (error) {
        console.log(error);
    }
};


export const asyncFetchProducts = (data: any) => async (dispatch: any, getState: any) => {
  try {
      dispatch(setLoadingProducts(true));

      // Destructure necessary parameters from the data object
      const { searchText, page, queryMinPrice, queryMaxPrice, filterParams, city, state, userId } = data;

      // Build the query string dynamically
      let query = `/searchProduct/${searchText}?page=${page}`;
      if (queryMinPrice !== null && queryMinPrice !== undefined) {
          query += `&minPrice=${queryMinPrice}`;
      }
      if (queryMaxPrice !== null && queryMaxPrice !== undefined) {
          query += `&maxPrice=${queryMaxPrice}`;
      }

      // Conditionally add city, state, and userId to the query string
      if (userId) {
          query += `&userId=${userId}`;
      }
      if (city && state) {
          query += `&city=${city}&state=${state}`;
      } else if (city) {
          query += `&city=${city}`;
      } else if (state) {
          query += `&state=${state}`;
      }

      // Fetch data from the API
      const response = await PostRequest(query, filterParams);
      const products = response.data.products || []; // Default to empty array if products are undefined
      const { totalPages, currentPage } = response.data;

      // Get the current cart from the state
      const cart = getState().cart.products || []; // Default to empty array if cart is undefined

      // Enhance the products with additional fields
      const enhancedProducts = products.map((product: any) => {
          const cartProduct = cart.find((item: any) => item.id === product.id);
          return {
              ...product,
              quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
              addToCartStatus: cartProduct ? "Update Cart" : "Add to cart",
          };
      });

      // Dispatch the products, even if empty
      dispatch(setSearchProducts(enhancedProducts));

      // Dispatch pagination details (defaults to 1 page if no data)
      dispatch(setPage({ totalPages: totalPages || 1, currentPage: currentPage || 1 }));

      // Ensure the loading state is turned off
      dispatch(setLoadingProducts(false));
  } catch (error) {
      console.error("Error fetching products:", error);

      // Handle errors by ensuring the state is updated
      dispatch(setSearchProducts([])); // Set products to an empty array
      dispatch(setPage({ totalPages: 1, currentPage: 1 })); // Reset pagination
      dispatch(setLoadingProducts(false)); // Turn off loading
  }
};


export const asyncAddToCart = (product: any, index: number) => async (dispatch: AppDispatch) => {
    try {
      const token = localStorage.getItem("userToken");
  
      if (!token) {
        const cartProducts = JSON.parse(localStorage.getItem("cart") || "[]");
        const productIndex = cartProducts.findIndex((item: any) => item._id === product._id);
  
        if (productIndex === -1) {
          // Add new product to cart
          cartProducts.push({ ...product, quantityByUser: 1 });
        } else {
          // Update existing product's quantity
          cartProducts[productIndex].quantityByUser += 1;
        }
  
        localStorage.setItem("cart", JSON.stringify(cartProducts));
        dispatch(
          setProduct({
            product: {
              ...product,
              quantityByUser: cartProducts[productIndex]?.quantityByUser || 1,
              addToCartStatus: "Update Cart",
            },
            index,
          })
        );
        dispatch(setCartLength(cartProducts.length));
  
        showSuccessToast("Product added to cart");
        return;
      }
  
      // Handle for logged-in user
      const response = await PostRequest("/addItemToCart", {
        productId: product?._id,
        quantity: product?.quantityByUser || 1,
      });
  
      dispatch(
        setProduct({
          product: { ...product, addToCartStatus: "Update Cart" },
          index,
        })
      );
      dispatch(setCartLength(response.data.count));
      showSuccessToast("Product added to cart");
    } catch (error: any) {
      if (
        error?.response?.data?.message === "Invalid User" ||
        error?.response?.data?.message === "Invalid token"
      ) {
        showInfoToast("Please Login");
      } else {
        showErrorToast(error?.response?.data?.message || "Failed to add item to cart");
      }
    }
  };
  

export const asyncUpdateQuantity = (
        product: any,index:number,
        newQuantity: number
      ) => async (dispatch: Function) => {
        const isLoggedIn = !!localStorage.getItem("userToken");
        const productId = product._id;

      
        try {
          if (!isLoggedIn) {
            const cartProducts = JSON.parse(localStorage.getItem("cart") || "[]");
      
      
            const productIndex = cartProducts.findIndex(
              (cartProduct: any) => cartProduct._id === productId
            );
      
            if (productIndex > -1) {
              if (newQuantity > 0) {
                cartProducts[productIndex].quantityByUser = newQuantity;
              } else {
                cartProducts.splice(productIndex, 1);
              }
            } else if (newQuantity > 0) {
              cartProducts.push({ ...product, quantityByUser: newQuantity });
            }
      
            localStorage.setItem("cart", JSON.stringify(cartProducts));
      
            dispatch(
              setProduct({
                product: { ...product, quantityByUser: newQuantity },
                index: productIndex,
              })
            );
      
            showSuccessToast(
              newQuantity > 0
                ? "Quantity updated successfully!"
                : "Product removed from cart."
            );
      
            dispatch(setCartLength(cartProducts.length));
          } else {
            const response = await PostRequest("/updateItemQuantity", {
              productId,
              quantity: newQuantity,
            });

      
            if (response?.data) {
              dispatch(
                setProduct({
                  product: { ...product, quantityByUser: newQuantity },
                  index: productId,
                })
              );
      
              showSuccessToast(
                newQuantity > 0
                  ? "Quantity updated successfully!"
                  : "Product removed from cart."
              );
      
              if (response.data.count !== undefined) {
                dispatch(setCartLength(response.data.count));
              }
            }
          }
        } catch (error: any) {
          showErrorToast(error?.response?.data?.message || "An error occurred.");
        }
      
};
          
    


export const asyncRemoveItem = (product: any, index: number) => async (dispatch: any) => {
        try {
          const isLoggedIn = localStorage.getItem("userToken");
      
          if (!isLoggedIn) {
            // Handle locally for non-logged-in user
            const cartProducts = JSON.parse(localStorage.getItem("cart") || "[]");
            const productIndex = cartProducts.findIndex((item: any) => item._id === product._id);
      
            if (productIndex !== -1) {
              if (cartProducts[productIndex].quantityByUser > 1) {
                cartProducts[productIndex].quantityByUser -= 1;
              } else {
                cartProducts.splice(productIndex, 1); // Remove product if quantity is 1
              }
              localStorage.setItem("cart", JSON.stringify(cartProducts));
      
              dispatch(
                setProduct({
                  product: {
                    ...product,
                    quantityByUser: cartProducts[productIndex]?.quantityByUser || 0,
                    addToCartStatus: cartProducts[productIndex] ? "Update cart" : "Add to cart",
                  },
                  index,
                })
              );
      
              showSuccessToast(
                cartProducts[productIndex] ? "Updated Successfully" : "Product removed from cart"
              );
              dispatch(setCartLength(cartProducts.length));
            }
          } else {
            // Handle for logged-in user
            const response = await PostRequest("/removeItemFromCart", {
              productId: product?._id,
            });
      
            showSuccessToast(response.data.message);
            dispatch(setCartLength(response.data.cart.items.length));
          }
        } catch (error: any) {
          showErrorToast(error.response?.data?.message || "Failed to remove item from cart");
        }
};
      