import React, { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import Filters from "./Filters";
import ProductList from "./ProdutList";
import { setPage } from "../../slice/SearchSlice";
import { asyncFetchfilters, asyncFetchProducts } from "../../actions/searchProductAction";
import Loader from "../../common/loader/Loader";
import Pagination from "./Pagination";
import { useMediaQuery } from "@react-hook/media-query";
import MobileFilter from "./Mobilefilter";
import { FiFilter } from "react-icons/fi";
import { loginUsingWhatsApp } from "../../slice/UserSlice";

interface SearchPageProps {
  lat: number;
  lng: number;
}

const SearchPage: React.FC<SearchPageProps> = ({lat,lng}) => {
  const { searchText }: any = useParams<{ searchText: string }>();
  const {user}=useSelector((state:RootState)=>state.user)
  const userId=user?._id
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();


  const isMobile = useMediaQuery("(max-width: 870px)");
  const [showMobileFilters, setShowMobileFilters] = useState(false);

  // Extract page from query params or default to 1
  const queryPage = parseInt(searchParams.get("page") || "1", 10);
  const queryMinPrice = searchParams.get("minPrice");
  const queryMaxPrice = searchParams.get("maxPrice");

  const { filters, isLoadingFilters, isLoading, searchProducts, totalPages } = useSelector(
    (state: RootState) => state.search
  );
  const filterParams = useMemo(() => {
    const params: { [key: string]: string[] | any } = {
      variations: {},
      brand: [],
      subCategory: []
    };
  

    searchParams.forEach((value: any, key: any) => {
      if (key !== "minPrice" && key !== "maxPrice" && key !== "page") {
        if (key === "brand") {
          params.brand.push(value);
        } else if (key === "SubCategory") {
          params.subCategory.push(value);
        } else if (Array.isArray(searchParams.getAll(key))) {
          if (!params.variations[key]) {
            params.variations[key] = [];
          }
          params.variations[key].push(value);
        } else {
          if (!params[key]) {
            params[key] = [];
          }
          params[key].push(value);
        }
      }
    });

    return params;
  }, [searchParams]);


  useEffect(() => {
    if (searchText) {
      const fetchFilters = async () => {
        try {
          await dispatch(asyncFetchfilters(searchText.toString()));
        } catch (error) {
          console.error("Error fetching filters:", error);
        }
      };

      fetchFilters();
    }
  }, [searchText, dispatch]);

  // Fetch products when `searchText` or `queryPage` changes

  useEffect(() => {
    const fetchData = async () => {
      const fetchUserLocation = async (latitude: number, longitude: number): Promise<{ city: string; state: string }> => {
        const apiKey = "AIzaSyC4FE1dcKZW4SE-Wb_7Pp-v5AECpBK6ojY";
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
          );
          const data = await response.json();
    
          if (data.status === "OK" && data.results.length > 0) {
            const addressComponents = data.results[0].address_components;
    
            let city = "";
            let state = "";
    
            // Loop through address components to find city and state
            addressComponents.forEach((component: any) => {
              if (component.types.includes("locality")) {
                city = component.long_name; // City
              }
              if (component.types.includes("administrative_area_level_1")) {
                state = component.long_name; // State
              }
            });
    
            return { city, state };
          }
    
          return { city: "", state: "" }; // Return empty values if no results
        } catch (error) {
          console.error("Error fetching location:", error);
          return { city: "", state: "" }; // Return empty values in case of an error
        }
      };
  
      // Set the city and state from the geolocation
      let city = "";
      let state = "";
  
      if (lat && lng) {
        const location = await fetchUserLocation(lat, lng);
        city = location.city;
        state = location.state;
      }
  
      if (searchText) {
        try {
          await dispatch(
            asyncFetchProducts({
              page: queryPage,
              searchText: searchText.toString(),
              queryMinPrice,
              queryMaxPrice,
              filterParams,
              city,
              state,
              userId
            })
          );
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      }
    };
  
    fetchData();
  
  }, [searchText, queryPage, queryMaxPrice, queryMinPrice, filterParams, lat, lng, dispatch]);
  
  // Handle page change
  const onPageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      const newParams = new URLSearchParams(searchParams.toString());
      newParams.set("page", newPage.toString());
      setSearchParams(newParams);
    }
  };


  return (
    <div className="container mx-auto p-4 mt-20">
      <h1 className="text-2xl mb-6 text-center text-gray-800 tracking-wide leading-tight mt-[10px] animate-fadeIn">
        Search Results for <span className="text-blue-500">"{searchText}"</span>
      </h1>

      <div
        className={`flex flex-row   md:flex-col gap-6 `}
      >        {/* Filters Section */}
        {isMobile ? (
          <>
            <button
              className="text-black flex flex-row"
              onClick={() => setShowMobileFilters((prev) => !prev)}
            >
              <span className="motta-svg-icon motta-svg-icon--filter">
                <svg
                  width="20"
                  height="20"
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  viewBox="0 0 32 32"
                >
                  <path d="M8 14.4h3.2v-9.6h-3.2v3.2h-4.8v3.2h4.8z"></path>
                  <path d="M24 17.6h-3.2v9.6h3.2v-3.2h4.8v-3.2h-4.8z"></path>
                  <path d="M14.4 8h14.4v3.2h-14.4v-3.2z"></path>
                  <path d="M3.2 20.8h14.4v3.2h-14.4v-3.2z"></path>
                </svg>
              </span>
              Filter

            </button>
            {showMobileFilters && (
             <>

                {isLoadingFilters ? (
                  <div className="flex items-center justify-center">
                    <Loader />
                  </div>
                ) : (
                  <MobileFilter
                    filters={filters}
                    close={() => setShowMobileFilters(false)}
                  />)}
              </>
            )}
          </>
        ) : (
          <aside className="md:w-[full] border p-4 rounded-lg">
            {isLoadingFilters ? (
              <div className="flex items-center justify-center">
                <Loader />
              </div>
            ) : (
              <Filters filters={filters} />
            )}
          </aside>
        )}

        {/* Product List Section */}
        <div className="relative w-3/4 md:w-full">
          {showMobileFilters && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-9 w-screen h-screen"></div>
          )}
          {isLoading ? (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 z-30">
              <Loader />
            </div>
          ) : (
            <>
              <ProductList
                products={searchProducts}
                totalPages={totalPages}
                currentPage={queryPage}
                onPageChange={onPageChange}
              />
            </>
          )}
        </div>



      </div>
    </div>
  );
};

export default SearchPage;
