import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GetRequest } from "../api/Request";

type Image = {
  publicId: string;
  url: string;
  _id: string;
};


type Blog = {
  id: number;
  images: Image[];
  title: string;
  description: string;
  date: string;
  metaDescription: string;
  metaKeyword: string;
  metaTitle: string;
};

interface BlogsState {
  blogs: Blog[];
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: BlogsState = {
  blogs: [],
  loading: false,
  error: null,
};

export const fetchBlogs = createAsyncThunk(
  "blogs/fetchBlogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await GetRequest("/getAllBlog");

      return response.data.data.docs;
    } catch (error: any) {
      console.error("Error fetching blogs:", error);
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs = action.payload;
        console.log("Blogs fetched successfully:", state.blogs);
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default blogsSlice.reducer;
