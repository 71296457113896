import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const HeroSection = (props: any) => {
  let { heading, info } = props;
  return (
    <div
      style={{
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
      className="sm:p-[0.5rem] basis-[23%] md:basis-[43%] xss:basis-[43%] sm:basis-[43%] p-[1rem] bg-white mx-auto 
      max-w-[95%] rounded-lg 
      no-scrollbar mt-[1rem] overflow-x-auto scrollbar-thin scrollbar-thumb-rounded relative"
    >
      <h2
  
        className="text-primary font-bold sm:text-xs xss:text-[9px]"
      >
        {heading}
      </h2>
      <div className="flex flex-wrap justify-center items-center">
        {info.length > 0 &&
          info.map((data: any) => {
            return (
              <Link
                className="flex justify-between sm:justify-center sm:items-center m-[0.3rem] items-center flex-col basis-[45%] sm:basis-[42%]"
                to={data.link}
              >
                <LazyLoadImage
                  effect="blur"
                  className="h-[120px] w-[120px] sm:h-[70px] sm:w-[70px] xss:w-[48px] xss:h-[48px] object-contain "
                  src={`${process.env.PUBLIC_URL}/heroSectionImage/${data.imgUrl}`}
                  alt="ac"
                  loading="lazy"
                />
                <p className="font-semibold	 text-[16px] sm:text-[10px] xss:text-[9px] text-center">
                  {data.name}
                </p>
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default HeroSection;
