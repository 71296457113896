import { Link } from "react-router-dom";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { useCallback, useEffect, useRef, useState } from "react";
import ProductForSale from "./ProductForSale";
import Brand from "./Brand";
import { GetRequest } from "../../api/Request";
import Loader from "../../common/loader/Loader";
import { useSelector } from "react-redux";
import { allProduct } from "../../slice/CartSlice";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import HeroSection from "./HeroSection";
import { IsLoggedIn } from "../../slice/UserSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useMediaQuery } from "@react-hook/media-query";

import Banners from "./Banners";
import Products from "./Products";
import Banner2 from "./Banner2";
import BestSellingProducts from "./BestSellingProducts";
import TopCellPhones from "./TopCellPhones";
import HomeAppliances from "./HomeAppliances";
import BannerCarosuel from "./BannerCarosuel";
import StyleSection from "./TopStories";
import { Helmet } from "react-helmet";

const Home = (props: any) => {
  let { allCategory, subCategories } = props;
  const containerRef = useRef<any>(null);
  const isMobile = useMediaQuery("(max-width: 500px)");
  const subCategoryRef = useRef<any>(null);
  const [latestProducts, setLatestProducts] = useState<any>([]);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [recommendedProducts, setRecommendedProducts] = useState<any>([]);
  const [isLoadingForRecommended, setIsLoadingForRecommended] = useState(false);
  const [allBanner, setAllBanner] = useState<any>([]);
  const [isLoadingForBanner, setIsLoadingForBanner] = useState<Boolean>(false);
  const cartProducts = useSelector(allProduct);
  const [isFading, setIsFading] = useState(false);
  const userIsLoggedIn = useSelector(IsLoggedIn);

  const prevSlide = () => {
    setIsFading(true);
    setTimeout(() => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? allBanner.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      setIsFading(false);
    }, 500); // Match this duration with your CSS transition duration
  };

  const nextSlide = () => {
    setIsFading(true);
    setTimeout(() => {
      const isLastSlide = currentIndex === allBanner.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
      setIsFading(false);
    }, 500); // Match this duration with your CSS transition duration
  };

  const getAllLatestProducts = useCallback(async () => {
    try {
      const response = await GetRequest("getAllProducts?latest=true");
      if (response.status === 200) {
        setLatestProducts(() =>
          response?.data?.products?.docs.map((product: any) => {
            const cartProduct =
              cartProducts &&
              cartProducts.find(
                (cartItem: any) => cartItem._id === product._id
              );
            return {
              ...product,
              addedToWishlist: false,
              quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
              addToCartStatus: cartProduct ? "Update Cart" : "Add to cart",
              isLoading: false,
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching latest products:", error);
    }
  }, [cartProducts]);

  const getAllBanner = useCallback(async () => {
    let url = "/getBannerAccToDevice?display_type=";
    url += isMobile ? "Mobile" : "Desktop";
    setIsLoadingForBanner(true);
    try {
      const response = await GetRequest(url);
      if (response.status === 200) {
        setAllBanner(response?.data);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
    setIsLoadingForBanner(false);
  }, [isMobile]);



  const getAllRecommendedProducts = useCallback(async () => {
    setIsLoadingForRecommended(true);
    try {
      let response = await GetRequest("/recommended-products");
      if (response?.data?.products?.length > 0) {
        setRecommendedProducts(response.data.products.map((product: any) => {
          const cartProduct = cartProducts && cartProducts.find((cartItem: any) => cartItem._id === product._id);
          return {
            ...product,
            addedToWishlist: false,
            quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
            addToCartStatus: cartProduct ? "Update Cart" : "Add to cart", // Set status based on cart presence
            isLoading: false,
          };
        }));
      } else {
        setRecommendedProducts([]);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoadingForRecommended(false);
  }, [cartProducts]); // Dependency: cartProducts


  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getAllLatestProducts(), getAllBanner()]);
    };
    fetchData();
  }, [getAllLatestProducts, getAllBanner]);

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      getAllRecommendedProducts();
    }
  }, [localStorage.getItem("userToken"), getAllRecommendedProducts]); // Include `getAllRecommendedProducts` as a dependency





  const scrollLeft = (ref: any) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: -400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const scrollRight = (ref: any) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: 400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const autoSlideInterval = setInterval(() => {
      nextSlide();
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(autoSlideInterval);
  }, [currentIndex, allBanner.length]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const keywords = [
    "Online Shopping in Gurugram",
    "online Shopping store",
    "Online Shopping Site",
    "Buy Online",
    "Shop Online",
    "Online Shopping",
    "Cayroshop",
    "Home Appliances",
    "Electronics Online",
    "Smart TVs",
    "Washing Machines",
    "Refrigerators",
    "Air Conditioners",
    "Microwave Ovens",
    "Kitchen Appliances",
    "Air Purifiers",
    "Dishwashers",
    "Affordable Electronics",
    "Online Appliance Store",
    "Best Electronics Deals",
    "Buy Electronics Online",
    "Latest Gadgets",
    "Top Electronics Brands",
    "Discount Home Appliances",
    "Quality Electronics",
    "LED Televisions",
    "Home Entertainment Systems",
    "Smart TVs",
    "LG OLED TVs",
    "Sony Bravia TVs",
    "4K TVs",
    "Xiaomi Mi LED TVs",
    "Panasonic Smart TVs",
    "QLED TVs",
    "Ultra HD TVs",
    "Smart TVs",
    "Android TVs",
  ];
  return (
    <div className="relative">
      <Helmet>
        <meta title="Cayroshop - Best Electronic Store in Gurugram" />
        <meta
          name="description"
          content="Shop at Cayroshop, the best electronic store in Gurugram. Explore LED TVs, washing machines, and more with top deals, quality products, and fast delivery!"
        />
        <meta
          name="keywords"
          content={keywords.join(", ")} // Joining all keywords into a single string
        />          <title>Cayroshop - Best Electronic Store in Gurugram</title>

      </Helmet>
      <div className="overfow-hidden">
        <div
          ref={containerRef}
          className="w-full overflow-y-hidden  overflow-x-auto mt-[6rem]   pl-[10px]  sm:pl-2 whitespace-nowrap no-scrollbar flex justify-center sm:justify-start lg:justify-start xl:justify-start xxl:justify-start"
        >
          {allCategory?.length > 0 &&
            allCategory.map((item: any, index: number) => {
              return (
                <div
                  className="category sm:!mr-[1rem] flex-shrink-0"
                  key={item._id}
                >
                  {/* It's a good practice to include a unique key for the list item */}
                  <Link
                    to={`product-list?category=${item.category.replace(
                      /\s+/g,
                      "-"
                    )}&category_id=${item._id}&page=1`}
                    className="flex justify-center items-center flex-col"
                  >
                    <LazyLoadImage
                      effect="blur"
                      src={item?.image?.url}
                      className="category-img"
                      alt="category"
                    />
                    <h2 className="text-[13px] font-[450] sm:max-w-[100px] mt-[0.3rem] sm:text-[11px] text-center">
                      {item?.category}
                    </h2>
                  </Link>
                </div>
              );
            })}
        </div>


        {/* <button
          onClick={() => scrollLeft(containerRef)}
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          }}
          className="absolute opacity-[0.6] left-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
          aria-label="Scroll Right"
        >
          <MdOutlineKeyboardArrowLeft />
        </button> */}
      </div>

      <div className="flex items-center mb-[3.5rem] top-[130px] z-0 sm:top-[83px]  xss:top-[5rem] mt-[0.5rem] sm:my-[0.9rem] absolute">
        {isLoadingForBanner && allBanner?.length === 0 ? (
          <div className="flex justify-center items-center h-[200px] sm:h-[83px] sm:w-[83vw] w-[100vw] ">
            <Loader />
          </div>
        ) : (
          <div
            // className="w-[100vw] m-auto  cursor-pointer  sm:mt-6 mt-6 sm:m-0 m-auto relative overflow-hidden"
            className="cursor-pointer mt-[2vh] xs:mt-[2vh] smm:mt-[2vh] md:md-[2vh]  relative overflow-hidden"

          >

            <Link
              to={allBanner[currentIndex]?.banner_link}
              className="cursor-pointer"
            >
              <LazyLoadImage
                effect="blur"
                src={allBanner?.length > 0 && allBanner[currentIndex]?.url}
                alt={allBanner[currentIndex]?.name}
                className={`w-[100vw] h-[700px] mt-[20px] xss:mt-[20px] smm:mt-[20px] sm:mt-[45px] xs:mt-0   sm:h-auto z-[-1] cursor-pointer object-cover object-top duration-500 transition-opacity ${isFading ? "opacity-40" : "opacity-100"
                  }`}
              />
            </Link>

            <div className="group-hover:block absolute top-[25%] sm:top-[35%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactLeft
                onClick={allBanner.length > 0 ? prevSlide : () => { }}
                size={20}
              />

            </div>

            <div className="group-hover:block absolute top-[25%] sm:top-[35%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactRight
                onClick={allBanner.length > 0 ? nextSlide : () => { }}
                size={20}
              />
            </div>
          </div>
        )}
      </div>

      <div className="sm:mt-[12rem] mt-[20rem] z-[999]">
        <div className="relative flex flex-wrap justify-around  ">
          <HeroSection
            heading={"Appliances for your Home | Up to 22% Off"}
            info={[
              {
                name: "Air Conditioner",
                link: "https://cayroshop.com/product-list?sub-category-id=66b3023377bbedb89846e6b5&page=1",
                imgUrl: "ac.webp",
              },
              {
                name: "Ceiling Fan",
                link: "https://cayroshop.com/product?name=1200mm-Fan-Usha-Striker-One-R-CF-Spanish-Green&product_id=66c9b5b7ad0fde84ea1a790d",
                imgUrl: "ceiling_fan.webp",
              },
              {
                name: "Television",
                link: "https://cayroshop.com/product-list?category=Television&category_id=66b1e0915472ab860dac9d27&page=1",
                imgUrl: "television.webp",
              },

              {
                name: "Washing Machine",
                link: "https://cayroshop.com/product-list?sub-category-id=66b31d08b8d58b966e370d71&page=1",
                imgUrl: "washing_machine.webp",
              },
              ,
            ]}
          />
          <HeroSection
            heading={"Appliances for your Kitchen | Up to 33% Off"}
            info={[
              {
                name: "Refrigerator",
                link: "https://cayroshop.com/product-list?sub-category-id=66b3066977bbedb89846e6bd&page=1",
                imgUrl: "refrigerator.webp",
              },
              {
                name: "Microwave Oven",
                link: "https://cayroshop.com/product-list?sub-category-id=66b4720304b4b470f4f7440b&page=1",
                imgUrl: "microwave_oven.webp",
              },

              {
                name: "Blender",
                link: "https://cayroshop.com/product-list?sub-category-id=66b33d1bb8d58b966e3713d1&page=1",
                imgUrl: "blender.webp",
              },
              {
                name: "Water Purifier",
                link: "https://cayroshop.com/product-list?sub-category-id=6703b86a94d50edb56735524&page=1",
                imgUrl: "water_purifier.webp",
              },
            ]}
          />
          <HeroSection
            heading={"Items which you can gift"}
            info={[
              {
                name: "Mobiles",
                link: "https://cayroshop.com/product-list?category=Mobiles&category_id=66b1de805472ab860dac9d1c&page=1",
                imgUrl: "mobiles.webp",
              },
              {
                name: "Headphones",
                link: "https://cayroshop.com/product-list?sub-category-id=67067e7d0c095ef7c895c3ee&page=1",
                imgUrl: "headphone.webp",
              },
              {
                name: "Laptops",
                link: "https://cayroshop.com/product-list?category=Laptops&category_id=67067b7c915de597bb4bc686&page=1",
                imgUrl: "laptop.webp",
              },
              {
                name: "Smart Watch",
                link: "https://cayroshop.com/product-list?sub-category-id=670676d9ed6b987d03197b68&page=1",
                imgUrl: "smart_watch.webp",
              },
            ]}
          />

          <HeroSection
            heading={"Appliances for the Comfort"}
            info={[
              {
                name: "Iron",
                link: "https://cayroshop.com/product-list?sub-category-id=66b459d304b4b470f4f741f5&page=1",
                imgUrl: "iron.png",
              },
              {
                name: "Slow Cooker",
                link: "https://cayroshop.com/product-list?sub-category-id=66b4618304b4b470f4f74310&page=1",
                imgUrl: "cooker.png",
              },
              {
                name: "Hair Dryer",
                link: "https://cayroshop.com/product?name=havells-hd1902-hair-dryer-1200-w-lavender-&product_id=67162504f730a37683d9acff",
                imgUrl: "hairdeir.png",
              },
              {
                name: "Room Heater",
                link: "https://cayroshop.com/product-list?sub-category-id=67517aeb2efcc2453048abfe&page=1",
                imgUrl: "room_heater.png",
              },
            ]}
          />

        </div>


        {/* {newProducts.length > 0 && (
          <ProductForSale
            products={newProducts}
            setProduct={setNewProducts}
            heading={"New Launches"}
            relatedProductPage={false}
          />
        )} */}


        {/* <NewArrival /> */}
        <HomeAppliances />
        <hr />

        <TopCellPhones />
        <div className="p-4  my-0 mx-auto z-10 relative top-[50%]">
          <h2 className="text-xl md:text-xl font-semibold  flex items-center justify-center gap-2">Shop By Products
            <hr className="flex-grow border-t-2 border-gray-300" />

          </h2>
          <button
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            }}
            onClick={() => scrollRight(subCategoryRef)}
            className="absolute right-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10 opacity-[0.6]"
            aria-label="Scroll Right"
          >
            <MdOutlineKeyboardArrowRight />
          </button>
          <ul
            ref={subCategoryRef}
            className="categories-on-home  overflow-y-scroll no-scrollbar"
            style={{
              justifyContent: "flex-start",
              marginBottom: "1rem",
            }}
          >
            {subCategories.length > 0 &&
              subCategories.map((subCat: any, index: number) => {
                return (
                  <li className="category flex justify-center ">
                    <Link
                      to={`product-list?sub-category-id=${subCat._id}&page=1`}
                      className="flex justify-center items-center flex-col"
                    >
                      <LazyLoadImage
                        effect="blur"
                        src={subCat?.image?.url}
                        // className=" w-[120px] h-[120px] object-cover bg-[white]"
                        className="category-img"
                        // style={{
                        //   width: "120px",
                        //   height: "120px",
                        // }}
                        alt="category "
                      />
                      <p className="text-[15px] sm:max-w-[70px] font-[480] mt-[0.3rem] sm:text-[11px] text-center">
                        {subCat?.name}
                      </p>
                    </Link>
                  </li>
                );
              })}
          </ul>
          <button
            onClick={() => scrollLeft(subCategoryRef)}
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            }}
            className="absolute opacity-[0.6] left-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
            aria-label="Scroll Right"
          >
            <MdOutlineKeyboardArrowLeft />
          </button>
        </div>

        <Banners />
        <BestSellingProducts />
        <Products />
        <Banner2 />
        <Brand />

        <hr />
        <BannerCarosuel />

        {
          userIsLoggedIn ? (
            recommendedProducts?.length > 0 ? (
              <ProductForSale
                products={recommendedProducts}
                setProduct={setRecommendedProducts}
                heading={"Recommended Products"}
                relatedProductPage={false}
              />
            ) : (
              latestProducts?.length > 0 && (
                <ProductForSale
                  products={latestProducts}
                  setProduct={setLatestProducts}
                  heading={"Latest Products"}
                  relatedProductPage={false}
                />
              )
            )
          ) : (
            latestProducts?.length > 0 && (
              <ProductForSale
                products={latestProducts}
                setProduct={setLatestProducts}
                heading={"Latest Products"}
                relatedProductPage={false}
              />
            )
          )
        }


        <StyleSection />

      </div>
    </div>
  );
};

export default Home;

{
  /* <div
          className="card"
          style={{
            width: "100%",
            maxWidth: "338px",
            bottom: "14px",
            marginTop: "1.3rem",
            marginRight: "2rem",
            padding: "2px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 4px",
            borderRadius: "6px",
          }}
        >
          <img 
            src="https://img.freepik.com/premium-vector/pos-payment-terminal-approved-payment-vector-stock-illustration_100456-2343.jpg?w=740"
            alt="Side Image"
            style={{ width: "100%", height: "auto", borderRadius: "4px" }}
          />
        </div> */
}
