import { useEffect, useRef, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { GetRequest, PostRequest } from "../../api/Request";
import Loader from "../../common/loader/Loader";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
  showWarningToast,
} from "../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { setUser, UserInfo } from "../../slice/UserSlice";
import { AppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  GoogleMap,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";

const DeliveryAddress = (props: any) => {
  // buy
  // const apiKey = "AIzaSyA9t7wajXqKZ8SpUT1kDRUwY5Rp1MYylcc";
  const postalCode = [
    122001, 122002, 122003, 122004, 122005, 122006, 122007, 122008, 122009,
    122010, 122011, 122015, 122016, 122017, 122018, 122051, 122052, 122101,
    122102, 122103, 122104, 122105, 122107, 122108, 122413, 122414, 122502,
    122503, 122504, 122505, 122506, 122508, 122106, 123401,
  ];
  const apiKey = "AIzaSyC4FE1dcKZW4SE-Wb_7Pp-v5AECpBK6ojY";
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [searchResult, setSearchResult] = useState<any>(null);
  const [hitAPI, setHitAPI] = useState<Boolean>(false);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    // libraries: process.env.REACT_APP_LIBRARIES as any,
    libraries: ["places"],
  });
  const containerStyle = {
    height: "30vh",
    marginBottom: "1.3rem",
    borderRadius: "0.4rem",
  };
  // sell
  let { showAddressForm, setShowAddAddress, heading } = props;
  const userDetail = useSelector(UserInfo);
  const navigate = useNavigate();
  const [isBillingAddress, setIsBillingAddress] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availablePostalCode, setAvailablePostalCode] = useState<any>();
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    address1: "",
    address2: "",
    city: "Gurugram",
    state: "Haryana",
    postalCode: "",
    country: "India",
    landmark: "",
  });
  const [errors, setErrors] = useState<any>({});
  const dispatch = useDispatch<AppDispatch>();
  // Create refs for each input field
  const nameRef = useRef<any>(null);
  const mobileNumberRef = useRef<any>(null);
  const line1Ref = useRef<any>(null);
  const line2Ref = useRef<any>(null);
  const cityRef = useRef<any>(null);
  const stateRef = useRef<any>(null);
  const postalCodeRef = useRef<any>(null);
  const countryRef = useRef<any>(null);
  const landmarkRef = useRef<any>(null);
  const urlParams = new URLSearchParams(window.location.search);

  //buy
  useEffect(() => {
    // Check if geolocation is supported
    if ("geolocation" in navigator) {
      // Query for permission status
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          handlePermission(result);

          // Listen for changes in permission status
          result.onchange = () => {
            handlePermission(result);
          };
        })
        .catch((error) => {
          console.error("Error checking permissions:", error);
        });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handlePermission = (result: any) => {
    if (result.state === "granted") {
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    } else if (result.state === "prompt") {
      requestLocationAccess();
    } else if (result.state === "denied") {
      showWarningToast(
        "Location access is denied. Please enable it in your browser settings."
      );
    }
  };

  const requestLocationAccess = () => {
    navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
  };

  const successFunction = (position: any) => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
  };

  const errorFunction = (error: any) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        showWarningToast(
          "To provide you with accurate product delivery timings, please allow location access."
        );
        break;
    }
  };

  // Handler to toggle the checkbox state
  const handleCheckboxChange = async (event: any) => {
    const { checked } = event.target; // Get the checked state directly

    // Clear errors if the checkbox is checked
    if (checked) {
      setErrors({});
    } else {
      // await fetchUserLocation();
      setFormData((prevData) => ({
        ...prevData,
        city: "Gurugram",
        state: "Haryana",
        country: "India",
        postalCode: "",
      }));
    }

    // Update the state based on the checked value
    setIsBillingAddress(checked); // Use checked directly instead of value
  };

  useEffect(() => {
    if (!latitude && !longitude) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(() => position.coords.latitude);
        setLongitude(() => position.coords.longitude);
      });
    }
  }, []);

  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete);
  }

  useEffect(() => {
    if (latitude !== 0 && longitude !== 0) {
      fetchUserLocation();
    }
  }, [latitude, longitude, hitAPI]);

  const getStateDetailsUsingPinCode = async (pinCode: any) => {
    let newError = { ...errors };

    //List of valid Gurugram postal codes
    const postalCodes = [
      122001, 122002, 122003, 122004, 122005, 122006, 122007, 122008, 122009,
      122010, 122011, 122015, 122016, 122017, 122018, 122051, 122052, 122101,
      122102, 122103, 122104, 122105, 122107, 122108, 122413, 122414, 122502,
      122503, 122504, 122505, 122506, 122508, 122106, 123401,
    ];

    if (postalCodes.includes(pinCode)) {
      return true;
    } else {
      return true;
    }
  };

  const fetchUserLocation = async () => {
    return await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
    )
      .then(async (response) => response.json())
      .then(async (response: any) => {
        let state: any;
        let city: any;
        let country: any;
        let postal_code: any;
        const addressComponents = response.results[0].address_components;

        // Loop through address components
        for (const component of addressComponents) {
          const types = component.types;

          if (types.includes("locality")) {
            city = component.long_name;
          }
          if (types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (types.includes("country")) {
            country = component.long_name;
          }
          if (types.includes("postal_code")) {
            postal_code = component.long_name;
          }
        }

        const isInGurugram = await getStateDetailsUsingPinCode(
          Number(postal_code)
        );

        if (!isBillingAddress) {
          if (isInGurugram) {
            setFormData((prevState: any) => {
              return {
                ...prevState,
                state,
                city,
                country,
                postalCode: postal_code,
              };
            });
            setErrors((prevState: any) => {
              return {
                ...prevState,
                postalCode: "",
              };
            });
          } else {
            setFormData((prevState: any) => {
              return {
                ...prevState,
                state,
                city,
                country,
                postalCode: postal_code,
              };
            });
            setErrors((prevState: any) => {
              return {
                ...prevState,
                postalCode: "Currently, we only deliver in Gurugram",
              };
            });
            showInfoToast("Currently, we only deliver in Gurugram");
          }
        } else {
          setFormData((prevState: any) => {
            return {
              ...prevState,
              state,
              city,
              country,
              postalCode: postal_code,
            };
          });
          setErrors((prevState: any) => {
            return {
              ...prevState,
              postalCode: "",
            };
          });
        }
      })
      .catch((err) => {
        setHitAPI(() => false);
      });
  };

  function onPlaceChanged() {
    if (searchResult != null) {
      let locationObj = searchResult.getPlace();
      setLatitude(locationObj.geometry.location.lat());
      setLongitude(locationObj.geometry.location.lng());
    }
  }

  const center = { lat: latitude, lng: longitude };

  //sell

  const handleCloseTheAddress = () => {
    setShowAddAddress(() => false);
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Update form data
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: name === "postalCode" ? value.slice(0, 6) : value, // Limit postal code to 6 characters
    }));

    // Clear previous error message if the field is postalCode
    if (name !== "postalCode") {
      setErrors((prevData: any) => ({
        ...prevData,
        [name]: "",
      }));
    }
    // Check if the postal code is valid and make an API call if necessary
    if (!isBillingAddress) {
      if (name === "postalCode") {
        const isInGurugram = await getStateDetailsUsingPinCode(Number(value));

        if (!isInGurugram) {
          setErrors((prevData: any) => ({
            ...prevData,
            postalCode: "We only deliver in Gurugram for now.",
          }));
        } else {
          setErrors((prevData: any) => ({
            ...prevData,
            postalCode: "",
          }));
        }
      }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault(); // Prevent default form submission behavior
    const newErrors: any = { ...errors };
    let firstErrorFieldId: string | null = null; // To store the ID of the first error field

    // Validate fields and populate errors
    if (!formData.name) {
      newErrors.name = "Name is required";
      firstErrorFieldId = "name"; // Set ID for focus
    }

    if (!formData.mobileNumber || formData.mobileNumber.length !== 10) {
      newErrors.mobileNumber = "Mobile number is required and should be valid";
      if (!firstErrorFieldId) firstErrorFieldId = "mobileNumber"; // Set ID for focus
    }

    if (!formData.address1) {
      newErrors.address1 = "Address Line 1 is required";
      if (!firstErrorFieldId) firstErrorFieldId = "address1"; // Set ID for focus
    }



    if (!formData.city) {
      newErrors.city = "City is required";
      if (!firstErrorFieldId) firstErrorFieldId = "city"; // Set ID for focus
    }

    if (!formData.state) {
      newErrors.state = "State is required";
      if (!firstErrorFieldId) firstErrorFieldId = "state"; // Set ID for focus
    }

    if (!formData.postalCode) {
      newErrors.postalCode = "Postal code is required";
      if (!firstErrorFieldId) firstErrorFieldId = "postalCode"; // Set ID for focus
    }

    if (!getStateDetailsUsingPinCode(formData.postalCode)) {
      newErrors.postalCode = "We only deliver products in gurugram.";
      if (!firstErrorFieldId) firstErrorFieldId = "postalCode"; // Set ID for focus
    }

    setErrors(newErrors);

    // Check if there are any errors
    if (Object.values(newErrors).some((error) => error !== "")) {
      // Focus on the first error field, if it exists
      if (firstErrorFieldId) {
        const errorField = document.getElementById(firstErrorFieldId);
        if (errorField) {
          errorField.focus(); // Focus on the input field with the corresponding ID
        }
      }

      return; // Exit early to prevent further execution
    }
    setIsLoading(true); // Set loading state to true before making the request
    let newFormData: any = { ...formData };

    if (latitude) {
      newFormData.latitude = latitude;
    }

    if (longitude) {
      newFormData.longitude = longitude;
    }

    PostRequest("/create-address", newFormData)
      .then((res: any) => {
        if (res.status === 201) {
          setFormData({
            name: "",
            mobileNumber: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            postalCode: "",
            country: "India",
            landmark: "",
          });

          dispatch(
            setUser({
              name: userDetail.name || "",
              number: userDetail.number || "",
              gender: userDetail.gender || "",
              email: userDetail.email || "",
              addresses: res.data.addresses,
              userIsLoggedIn: true,
              _id: userDetail._id || "",
            })
          );

          setErrors({}); // Clear errors
          setShowAddAddress(() => false); // Hide the add address form
          showSuccessToast("Added successfully");

          if (urlParams.get("redirect") === "cart") {
            navigate("/cart"); // Redirect to the desired URL
          }
        }
      })
      .catch((error) => {
        console.error("Failed to add address:", error);
        showErrorToast("Failed to add address");
      })
      .finally(() => {
        setIsLoading(false); // Reset loading state after request completes
      });
  };

  return (
    <div className="fixed w-[100vw] mt-[3rem]  h-[100vh] z-[99] top-0 flex flex-col left-0 justify-center overflow-x-scroll bg-[#868786] bg-opacity-[0.5] backdrop-blur-xs">
      <div className="w-full h-[70vh] relative overflow-x-scroll flex justify-center my-0 mx-auto rounded-lg">
        <div className="p-[1rem] border w-[60%] relative overflow-x-scroll sm:w-[97%] border-gray-300  bg-white rounded-lg">
          <div className="flex justify-between items-start">
            <h1 className="font-bold text-[19px] mb-[1rem] ">
              Add Shipping/Billing Address
            </h1>
            <span
              className="text-red-600 cursor-pointer"
              onClick={handleCloseTheAddress}
            >
              <RxCross1 />
            </span>
          </div>
          <div className="bg-orange-100 border border-orange-300 rounded-lg px-3 py-2 mb-6 shadow-md">
            <div className="flex items-center mb-2">
              <svg
                className="w-6 h-6 text-orange-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <h4 className="text-[13px] font-bold text-orange-600 ml-2">
                Important Note
              </h4>
            </div>
            <p className="text-gray-600 text-normal sm:text-xs">
              We only deliver within Gurugram. Please ensure your delivery
              address is within our service area.
            </p>{" "}
            <input
              type="checkbox"
              checked={isBillingAddress}
              onChange={handleCheckboxChange}
              id="billing"
            />
            <label
              htmlFor="billing"
              className="text-gray-600 text-normal sm:text-xs mt-[0.5rem]"
            >
              <span className="font-bold mx-[0.5rem]">Billing Address -</span>
              If you're filling out a billing address, please click here to
              avoid delivery errors!
            </label>
          </div>
          <div className="relative">
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={18}
                onClick={(event: any) => {
                  let lat = event.latLng.lat();
                  let lng = event.latLng.lng();
                  setLatitude(() => lat);
                  setLongitude(() => lng);
                }}
                options={{
                  mapTypeControl: false, // Hides the map type control (satellite option)
                  streetViewControl: false, // Hides the street view control
                  fullscreenControl: false, // Optionally hide the fullscreen control
                  zoomControl: true, // Keep zoom control if needed
                  styles: [
                    // Optional: Custom styles for further customization
                    {
                      featureType: "all",
                      elementType: "labels",
                      stylers: [{ visibility: "off" }], // Hide all labels
                    },
                  ],
                }}
              >
                <MarkerF
                  onDragEnd={(event: any) => {
                    let lat = event.latLng.lat();
                    let lng = event.latLng.lng();
                    setLatitude(() => lat);
                    setLongitude(() => lng);
                  }}
                  draggable={true}
                  position={center}
                />

                <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                  <div className="flex justify-center">
                    <input
                      type="text"
                      placeholder="search your location"
                      className="md:w-[90%] w-[50%]"
                      style={{
                        border: "1px solid #E0E0E0",
                        marginTop: "1rem",
                        boxSizing: `border-box`,
                        height: `42px`,
                        padding: `0 12px`,
                        borderRadius: `5px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        position: "absolute",
                      }}
                      name="searchText"
                    />
                  </div>
                </Autocomplete>
                <button className="w-[22px] -ml-7 inline">
                  <svg
                    className="z-50"
                    viewBox="0 0 24 24"
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <g>
                      <path d="M20.87,20.17l-5.59-5.59C16.35,13.35,17,11.75,17,10c0-3.87-3.13-7-7-7s-7,3.13-7,7s3.13,7,7,7c1.75,0,3.35-0.65,4.58-1.71 l5.59,5.59L20.87,20.17z M10,16c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S13.31,16,10,16z"></path>
                    </g>
                  </svg>
                </button>
              </GoogleMap>
            ) : (
              <h1>{isLoaded}</h1>
            )}
          </div>
          <div>
            <label className="block mb-6">
              <span className="text-gray-700">Your name *</span>
              <input
                ref={nameRef}
                name="name"
                type="text"
                id="name"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder="Your full name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && (
                <p className="text-red-500 text-sm">{errors.name}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Mobile Number *</span>
              <input
                ref={mobileNumberRef}
                name="mobileNumber"
                type="tel"
                id="mobileNumber"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder="898989XXXX"
                value={formData.mobileNumber}
                onChange={handleChange}
                required
              />
              {errors.mobileNumber && (
                <p className="text-red-500 text-sm">{errors.mobileNumber}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Line 1 *</span>
              <input
                ref={line1Ref}
                name="address1"
                type="text"
                id="address1"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder=""
                value={formData.address1}
                onChange={handleChange}
                required
              />
              {errors.address1 && (
                <p className="text-red-500 text-sm">{errors.address1}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Line 2 *</span>
              <input
                ref={line2Ref}
                name="address2"
                type="text"
                id="address2"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder=""
                value={formData.address2}
                onChange={handleChange}
                required
              />
            </label>
        
            <label className="block mb-6">
              <span className="text-gray-700">City *</span>
              <input
                ref={cityRef}
                name="city"
                type="text"
                id="city"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder=""
                value={formData.city}
                //readOnly={isBillingAddress ? false : true}
                onChange={handleChange}
                required
              />
              {errors.city && (
                <p className="text-red-500 text-sm">{errors.city}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">State *</span>
              <input
                ref={stateRef}
                name="state"
                type="text"
                id="state"
                //readOnly={isBillingAddress ? false : true}
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder=""
                value={formData.state}
                onChange={handleChange}
                required
              />
              {errors.state && (
                <p className="text-red-500 text-sm">{errors.state}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Postal Code *</span>
              <input
                ref={postalCodeRef}
                name="postalCode"
                type="tel"
                id="postalCode"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder="eg: 111000"
                value={formData.postalCode}
                onChange={handleChange}
                minLength={6}
                min={6}
                autoComplete={"false"}
                required
              />
              {errors.postalCode && (
                <p className="text-red-500 text-sm">{errors.postalCode}</p>
              )}
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Country *</span>
              <input
                ref={countryRef}
                name="country"
                type="text"
                id="country"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                value={formData.country}
                //readOnly={true}
              />
            </label>
            <label className="block mb-6">
              <span className="text-gray-700">Landmark</span>
              <input
                ref={landmarkRef}
                name="landmark"
                type="text"
                id="landmark"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-[1px] border-solid border-[#ddd] p-[0.4rem]"
                placeholder=""
                value={formData.landmark}
                onChange={handleChange}
              />
            </label>
            <div className="text-right  pb-[1rem] w-[100%]">
              <button
                onClick={isLoading ? () => {} : handleSubmit}
                className="h-10 px-5 relative text-indigo-100 bg-indigo-700 rounded-lg transition-colors duration-150 focus:shadow-outline hover:bg-indigo-800"
              >
                {isLoading ? (
                  <div className="absolute top-[-17px] left-[0%]">
                    <Loader />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeliveryAddress;
