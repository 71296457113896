import React, { useState, useRef } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { IoMdHeartEmpty, IoMdHeart } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setCartLength } from "../../slice/CartSlice";
import { Link, useNavigate } from "react-router-dom";
import { IsLoggedIn } from "../../slice/UserSlice";
import { addToWishlist, removeFromWishlist } from "../../slice/WishlistSlice";
import { AppDispatch } from "../../store/store";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from "../../utils/toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PostRequest } from "../../api/Request";


const ProductForSale = (props: any) => {
  let { products, heading, setProduct, relatedProductPage } = props;

  // console.log(props)
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const userIsLoggedIn = useSelector(IsLoggedIn);
  // New state to track loading for each product
  const [loadingProducts, setLoadingProducts] = useState<{
    [key: string]: boolean;
  }>({});

  const calculateDiscountPercentage = (
    salePrice: number,
    regularPrice: number
  ) => {
    const discountPercentage =
      ((regularPrice - salePrice) / regularPrice) * 100;
    return discountPercentage.toFixed(2); // Returns percentage with two decimal places
  };

  // Updated handleAddToCart function
  const handleAddToCart = async (product: any, index: number) => {
    let updateAllProduct = [...products];
    setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));
    console.log(product)
    try {
      const token = localStorage.getItem("userToken"); // Check if the user is logged in

      if (!token) {
        // If no token, save to localStorage
        const localCart = JSON.parse(localStorage.getItem("cart") || "[]");
        const existingProductIndex = localCart.findIndex(
          (item: any) => item._id === product._id
        );

        if (existingProductIndex > -1) {
          // Update quantity if product already exists in the cart
          localCart[existingProductIndex].quantityByUser +=
            product.quantityByUser;
        } else {
          // Add new product to the cart
          localCart.push({
            ...product,
            quantityByUser: product.quantityByUser,
          });
        }

        localStorage.setItem("cart", JSON.stringify(localCart)); // Save updated cart
        dispatch(setCartLength(localCart.length)); // Update cart length in Redux
        showSuccessToast("Product added to cart");

        // Update the product's addToCartStatus in the UI
        updateAllProduct[index].addToCartStatus = "Update Cart";
        // setProduct(() => updateAllProduct);
        return; // Exit early as the product is saved locally
      }

      // If token exists, proceed with the API call
      let response = await PostRequest("/addItemToCart", {
        productId: product?._id,
        quantity: product?.quantityByUser,
      });

      if (response.status === 200) {
        updateAllProduct[index].addToCartStatus = "Update Cart"; // Update the UI
        dispatch(setCartLength(response.data.count)); // Update cart length in Redux
        // setProduct(() => updateAllProduct); // Update product state

        if (product.quantityByUser === 1 && response.data.item.quantity === 1) {
          showSuccessToast("Product added to cart");
        } else {
          showSuccessToast("Updated Successfully");
        }
      }
    } catch (error: any) {
      // Handle error appropriately
      if (
        error?.response?.data?.message === "Invalid User" ||
        error?.response?.data?.message === "Invalid token"
      ) {
        showInfoToast("Please Login");
        navigate("/login-by-otp");
      } else {
        showErrorToast(error?.response?.data?.message);
      }
    } finally {
      // Reset loading state for this specific product
      setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
    }
  };

  const handleIncrementForQuantity = async (product: any, index: number) => {
    let updateAllProduct = [...products];
    setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));

    try {
      const token = localStorage.getItem("userToken"); // Check if the user is logged in

      if (!token) {
        // No token, update quantity in localStorage
        const localCart = JSON.parse(localStorage.getItem("cart") || "[]");
        const existingProductIndex = localCart.findIndex(
          (item: any) => item._id === product._id
        );

        if (existingProductIndex > -1) {
          const productInCart = localCart[existingProductIndex];

          // Ensure quantity does not exceed stock
          if (productInCart.quantityByUser < product.totalStock) {
            productInCart.quantityByUser += 1;
            localCart[existingProductIndex] = productInCart;
            localStorage.setItem("cart", JSON.stringify(localCart));

            updateAllProduct[index].quantityByUser += 1;
            // setProduct(() => updateAllProduct);
            showSuccessToast("Quantity updated");
          } else {
            showErrorToast("Cannot exceed available stock");
          }
        } else {
          showErrorToast("Product not found in cart");
        }
        return; // Exit early since we handled it locally
      }

      // Token exists, make the API call
      let response = await PostRequest("/updateItemQuantity", {
        productId: product?._id,
        quantity: product?.quantityByUser + 1,
      });

      if (response.status === 200) {
        // Ensure quantity does not exceed stock
        if (
          updateAllProduct[index].quantityByUser <
          updateAllProduct[index].totalStock
        ) {
          updateAllProduct[index].quantityByUser += 1;
          // setProduct(() => updateAllProduct);
          showSuccessToast("Updated successfully");
        } else {
          showErrorToast("Cannot exceed available stock");
        }
      }
    } catch (error: any) {
      showErrorToast(error?.response?.data?.message); // Handle error appropriately
    } finally {
      // Reset loading state for this specific product
      setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
    }
  };

  const handleDecrementForQuantity = async (product: any, index: number) => {
    let updateAllProduct = [...products];
    const token = localStorage.getItem("userToken"); // Check if the user is logged in

    // Set loading state for this specific product
    setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));

    try {
      if (!token) {
        // No token, update quantity in localStorage
        const localCart = JSON.parse(localStorage.getItem("cart") || "[]");
        const existingProductIndex = localCart.findIndex(
          (item: any) => item._id === product._id
        );

        if (existingProductIndex > -1) {
          const productInCart = localCart[existingProductIndex];

          if (productInCart.quantityByUser === 1) {
            // Remove item from localStorage cart if quantity is 1
            localCart.splice(existingProductIndex, 1);
            localStorage.setItem("cart", JSON.stringify(localCart));

            // Update UI state to reflect removal
            updateAllProduct[index].quantityByUser = 1;
            updateAllProduct[index].addToCartStatus = "Add to cart";
            // setProduct(() => updateAllProduct);
          } else {
            // Decrement quantity when it's greater than 1
            productInCart.quantityByUser -= 1;
            localCart[existingProductIndex] = productInCart;
            localStorage.setItem("cart", JSON.stringify(localCart));

            updateAllProduct[index].quantityByUser -= 1;
            // setProduct(() => updateAllProduct);
            showSuccessToast("Quantity updated");
          }
        }
        dispatch(setCartLength(localCart.length)); // Update cart length based on local cart
        return; // Exit early since we handled it locally
      }

      // Token exists, make the API call
      if (product.quantityByUser === 1) {
        // Remove item from cart when quantity is 1
        let response = await PostRequest("/removeItemFromCart", {
          productId: product?._id,
        });

        if (response.status === 200) {
          // Update state to reflect removal
          updateAllProduct[index].quantityByUser = 1;
          updateAllProduct[index].addToCartStatus = "Add to cart";
          // setProduct(() => updateAllProduct);
          dispatch(setCartLength(response.data.cart.items.length));
        }
      } else {
        // Decrement quantity when it's greater than 1
        let response = await PostRequest("/updateItemQuantity", {
          productId: product?._id,
          quantity: product?.quantityByUser - 1,
        });

        if (response.status === 200) {
          updateAllProduct[index].quantityByUser -= 1;
          // setProduct(() => updateAllProduct);
          showSuccessToast("Updated successfully");
          dispatch(setCartLength(response.data.count)); // Update cart length from response
        }
      }
    } catch (error: any) {
      showErrorToast(error?.response?.data?.message);
    } finally {
      // Reset loading state for this specific product
      setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
    }
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  // const generateProductStructuredData = (products: any) => {
  //   if (!products || products.length === 0) return null;

  //   // Get the current domain (protocol + domain + port if needed)
  //   const baseUrl = window.location.origin; // This gets the domain where the site is hosted

  //   return products.map((product: any) => {
  //     const structuredData = {
  //       "@context": "https://schema.org/",
  //       "@type": "Product",
  //       name: product.productName || "Product Name",
  //       image: product.images[0]?.url || "default-product-image.jpg", // First image URL
  //       description: `Buy ${product.productName} at discounted price from our store.`,
  //       sku: product._id || "Product SKU",
  //       offers: {
  //         "@type": "Offer",
  //         url: `${baseUrl}/product?name=${product.productName}&product_id=${product._id}`, // URL to the product page on your domain
  //         priceCurrency: "INR", // Assuming price is in INR
  //         price: product.salePrice || product.regularPrice,
  //         priceValidUntil: "2024-12-31", // Optional, set a date for offer validity
  //         itemCondition: "https://schema.org/NewCondition",
  //         availability:
  //           product.totalStock > 0
  //             ? "https://schema.org/InStock"
  //             : "https://schema.org/OutOfStock",
  //         eligibleRegion: {
  //           "@type": "Place",
  //           name: "India",
  //         },
  //       },
  //       aggregateRating: {
  //         "@type": "AggregateRating",
  //         ratingValue: "4.5", // You can update this based on reviews
  //         reviewCount: "100", // Update with actual review count
  //       },
  //     };

  //     return (
  //       <script key={product._id} type="application/ld+json">
  //         {JSON.stringify(structuredData)}
  //       </script>
  //     );
  //   });
  // };
  // const productStructuredData = generateProductStructuredData(products);



  return (
    <div
      className="sm:pt-[0.5rem] mx-auto p-4  no-scrollbar mt-[1rem]
      overflow-x-auto scrollbar-thin scrollbar-thumb-rounded
      relative"
    >
<h1 className="text-3xl md:text-xl font-semibold flex items-center justify-center gap-2">
{heading}
                <hr className="flex-grow border-t-2 border-gray-300" />
            </h1>      {/* <Helmet>{productStructuredData}</Helmet> */}

      <button
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        }}
        onClick={scrollRight}
        
        className="absolute right-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
        aria-label="Scroll Right"
      >
        <MdOutlineKeyboardArrowRight />
      </button>
      <div
        ref={containerRef}
        className="flex p-2 md:p-0 overflow-x-auto no-scrollbar mt-[1rem] flex-row"
        style={{ overflowX: "scroll" }} // Hide the scrollbar
      >
        {products?.length > 0 &&
          products?.map((product: any, index: number) => (
            <div
              className="item flex-none m-[1rem] bg-white sm:basis-[35%] sm:m-[0.4rem] basis-[20%] flex-shrink-0 p-2 sm:p-[0.5rem]  transform transition-transform duration-300 ease-in-out relative rounded-lg flex flex-col justify-between"
              style={{
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              key={index}
            >
              {(() => {
                const tagMap: any = {
                  trending: {
                    label: "Trending",
                    color:
                      "text-center text-xs sm:text-sm bg-gradient-to-r from-green-400 to-green-600",
                  },
                  latest: {
                    label: "Latest",
                    color:
                      "text-center text-xs sm:text-sm bg-gradient-to-r from-yellow-400 to-yellow-600",
                  },
                  hotDeals: {
                    label: "Hot Deals",
                    color:
                      "text-center text-xs sm:text-sm bg-gradient-to-r from-red-400 to-red-600",
                  },
                  dailyDeals: {
                    label: "Daily Deals",
                    color:
                      "text-center text-xs sm:text-sm bg-gradient-to-r from-purple-400 to-purple-600",
                  },
                  newLaunches: {
                    label: "Newly",
                    color:
                      "text-center text-xs sm:text-sm bg-gradient-to-r from-sky-400 to-sky-600",
                  },
                };

                let tag = Object.keys(tagMap).find((key) => product[key]);

                if (!tag) return <span className="w-[75px]  rounded-full justify-center inline-flex items-center px-2  sm:px-3 sm:py-2 h-6 "></span>;

                const tagDetails = tagMap[tag];

                return (
                  <span
                    className={`w-[80px] justify-center h-6 inline-flex items-center px-2 py-1 sm:px-3 sm:py-2 ${tagDetails.color} 
        text-white font-medium rounded-full shadow-lg transform transition-all duration-300 ease-in-out 
        hover:rotate-0 hover:scale-105 whitespace-nowrap`}
                  >
                    <span className="text-base sm:text-lg mr-1">
                      {tagDetails.icon}
                    </span>
                    {tagDetails.label}
                  </span>
                );
              })()}

              {/* {produc.totalStock === 0 ? (
                <p className="text-blue-800 sm:text-[10px] absolute sm:top-[1px] top-[7px] left-[6px] z-[9999] my-[0.2rem] text-sm text-left">
                  Out of stock!
                </p>
              ) : (
                ""
              )}
              {product.quantityByUser == product.totalStock ? (
                <p className="text-blue-800 sm:text-[10px] sm:top-[1px] absolute top-[7px] left-[6px] z-[9999] my-[0.2rem] text-sm text-left">
                  Only {product.totalStock} left in stock
                </p>
              ) : (
                ""
              )} */}
              {/* <p className="text-sm flex items-center justify-start ">
                <input
                  className="border-[0.5px] mr-[0.45rem] cursor-pointer border-solid border-[black] rounded-lg"
                  type="checkbox"
                  checked={
                    compareTheseProducts.length > 0
                      ? compareTheseProducts.some(
                          (compProduct: any) => compProduct._id === product._id
                        )
                      : false
                  }
                  onClick={() => handleClickOnCompare(product)}
                />
                Compare
              </p> */}
              <Link
                to={`/product?name=${product.productName
                  .replace(/[^a-zA-Z0-9]/g, "-") // Replace all non-alphanumeric characters with dashes
                  .replace(/-+/g, "-") // Replace multiple consecutive dashes with a single dash
                  .toLowerCase()}&product_id=${product._id || product.id}`}
                className="flex mt-[0.9rem] justify-center items-center"
              >
                <LazyLoadImage
                  effect="blur"
                  src={product?.images[0]?.url}
                  alt={`Carousel item ${index + 1}`}
                  className="w-full h-[220px] !flex justify-center items-center  sm:h-[100px] object-contain rounded-lg transition-transform duration-300 transform hover:scale-105"
                />
              </Link>
              {heading !== "Related Products" && (
                <div className="absolute cursor-pointer top-[7px] sm:top-0 right-0 sm:right-[1px] text-red-500 sm:text-[1rem] px-2 py-1  text-2xl font-bold hover:bg-red">
                  {!product.addedToWishlist ? (
                    <IoMdHeartEmpty
                      onClick={async () => {
                        if (userIsLoggedIn) {
                          await dispatch(addToWishlist(product));
                          await setProduct((prevAllProduct: any) => {
                            return prevAllProduct.map(
                              (item: any, i: number) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    addedToWishlist: true,
                                  };
                                }
                                return item;
                              }
                            );
                          });
                        } else {
                          navigate("/login-by-otp");
                        }
                      }}
                      // onClick={async () => {
                      //   await dispatch(addToWishlist(product));
                      //   await setProduct((prevAllProduct: any) => {
                      //     prevAllProduct[index].addedToWishlist = true;
                      //     return prevAllProduct;
                      //   });
                      // }}
                      className="text-red hover:fill-red"
                    />
                  ) : (
                    <IoMdHeart
                      onClick={async () => {
                        if (userIsLoggedIn) {
                          await dispatch(removeFromWishlist(product));
                          await setProduct((prevAllProduct: any) => {
                            return prevAllProduct.map(
                              (item: any, i: number) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    addedToWishlist: false,
                                  };
                                }
                                return item;
                              }
                            );
                          });
                        } else {
                          navigate("/login-by-otp");
                        }
                      }}
                    />
                  )}
                </div>
              )}
              <div className="text-center flex-grow">
                <h3 className="text-[#f55a01] text-lg sm:text-[0.78rem] font-semibold">
                  ₹{product.salePrice}{" "}
                  {/* <span className="!text-[#CD0B39] sm:hidden font-semibold ml-[0.3rem] !text-sm">
                    {calculateDiscountPercentage(
                      Number(product?.salePrice),
                      Number(product?.regularPrice)
                    ) + "% off"}
                  </span> */}
                </h3>
                <h3 className="line-through sm:block text-[0.75rem] ml-[0.3rem] text-gray-500">
                  ₹{product.regularPrice} 
                </h3>
                <h4
                  className={
                    relatedProductPage
                      ? "text-gray-900 mt-[0.35rem] mb-[0.35rem] sm:my-[0.2rem] text-[0.9rem] font-[450] sm:text-xs sm:font-[400] line-clamp-4"
                      : "text-gray-900 mt-[0.35rem] mb-[0.35rem] sm:my-[0.2rem] text-[0.9rem] font-[450] sm:text-xs sm:font-[400] line-clamp-2"
                  }
                >
                  <Link
                    to={`/product?name=${product.productName}&product_id=${product._id}`}
                  >
                    {product.productName}
                  </Link>
                </h4>
                <span className="!text-[#CD0B39] sm:!text-[12px] font-semibold ml-[0.3rem] !text-md ">
                  {calculateDiscountPercentage(
                    Number(product?.salePrice),
                    Number(product?.regularPrice)
                  ) + "% off"}
                </span>
              </div>
              {/* <!-- quantity  --> */}
              {!relatedProductPage ? (
                // product.totalStock === 0 ? (
                //   ""
                // ) : (
                <>
                  <div
                    className={
                      product?.addToCartStatus !== "Update Cart"
                        ? "hidden"
                        : "py-3 px-1 sm:px-[0.15rem] bg-white w-[100%] rounded-lg flex items-center sm:m-0"
                    }
                  >
                    {product.addToCartStatus === "Update Cart" && (
                      <div className="w-full flex justify-between items-center gap-x-3">
                        <div className="relative w-full flex items-center">
                          <input
                            id="hs-validation-name-error"
                            className="w-[90%] sm:w-[45px] sm:h-[20px] text-sm outline-none py-[0.1rem] border-[0.8px] border-solid border-[#ddd] pl-[0.8rem] rounded-lg bg-transparent border-0 text-gray-800 focus:ring-0"
                            type="number"
                            value={product?.quantityByUser}
                            data-hs-input-number-input=""
                            aria-describedby="hs-validation-name-error-helper"
                          />
                          {loadingProducts[product._id] && (
                            <div className="inline-block top-[-25px] right-[19px] absolute">
                              <div className="lds-ring text-black">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-end items-center gap-x-1.5 ">
                          {!loadingProducts[product._id] && (
                            <>
                              <button
                                type="button"
                                onClick={() =>
                                  handleDecrementForQuantity(product, index)
                                }
                                className="size-6 sm:size-[1.25rem] inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                                data-hs-input-number-decrement=""
                              >
                                <svg
                                  className="flex-shrink-0 size-3.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path d="M5 12h14"></path>
                                </svg>
                              </button>

                              <button
                                type="button"
                                className="size-6 sm:size-[1.25rem] inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                                data-hs-input-number-increment=""
                                onClick={() =>
                                  handleIncrementForQuantity(product, index)
                                }
                              >
                                <svg
                                  className="flex-shrink-0 size-3.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path d="M5 12h14"></path>
                                  <path d="M12 5v14"></path>
                                </svg>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {product.totalStock === 0 ? (
                    <div
                      className={
                        "flex justify-center items-center cursor-not-allowed w-[100%] sm:my-[0.5rem]"
                      }
                    >
                      <button className="bg-[#B7B7B7] cursor-not-allowed text-white text-center h-auto w-[75%] sm:h-[25px] sm:mt-0 xss:w-[100%] relative sm:m-0 my-[0.7rem] rounded-lg  p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem]">
                        Out Of Stock
                      </button>
                    </div>
                  ) : (
                    <div
                      className={
                        product.addToCartStatus === "Update Cart"
                          ? "hidden"
                          : "flex justify-center items-center w-[100%] sm:my-[0.5rem]"
                      }
                    >
                      <button
                        onClick={() => {
                          if (!loadingProducts[product._id]) {
                            handleAddToCart(product, index);
                          }
                        }}
                        className={
                          loadingProducts[product._id]
                            ? "bg-indigo-700 h-[33.13px] text-center w-[75%] sm:h-[25px] sm:mt-0 xss:w-[100%] relative sm:m-0 my-[0.7rem] rounded-lg text-white p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem] hover:bg-blue-600"
                            : "bg-indigo-700 text-center h-auto w-[75%] sm:h-[25px] sm:mt-0 xss:w-[100%] relative sm:m-0 my-[0.7rem] rounded-lg text-white p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem] hover:bg-blue-600"
                        }
                      >
                        {loadingProducts[product._id] ? (
                          <div className="absolute top-[-19px] left-[38%] sm:left-[30%] sm:top-[-19px] h-[45px]">
                            <div className="lds-ring">
                              <div className="sm:!h-[18px] sm:!w-[18px] "></div>
                              <div className="sm:!h-[18px] sm:!w-[18px] "></div>
                              <div className="sm:!h-[18px] sm:!w-[18px] "></div>
                              <div className="sm:!h-[18px] sm:!w-[18px] "></div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {product.addToCartStatus}
                            <FaShoppingCart className="inline mb-[0.2rem] ml-[0.2rem]" />
                          </>
                        )}
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div
                    className={
                      product?.addToCartStatus !== "Update Cart"
                        ? "hidden"
                        : "py-3 px-1 sm:px-[0.15rem] bg-white w-[100%] rounded-lg flex items-center sm:m-0"
                    }
                  >
                    {product.addToCartStatus === "Update Cart" && (
                      <div className="w-full flex justify-between items-center gap-x-3">
                        <div className="relative w-full flex items-center">
                          <input
                            id="hs-validation-name-error"
                            className="w-[90%] sm:w-[45px] sm:h-[20px] text-sm outline-none py-[0.1rem] border-[0.8px] border-solid border-[#ddd] pl-[0.8rem] rounded-lg bg-transparent border-0 text-gray-800 focus:ring-0"
                            type="number"
                            value={product?.quantityByUser}
                            data-hs-input-number-input=""
                            aria-describedby="hs-validation-name-error-helper"
                          />
                          {loadingProducts[product._id] && (
                            <div className="inline-block top-[-25px] right-[19px] absolute">
                              <div className="lds-ring text-black">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-end items-center gap-x-1.5 ">
                          {!loadingProducts[product._id] && (
                            <>
                              <button
                                type="button"
                                onClick={() =>
                                  handleDecrementForQuantity(product, index)
                                }
                                className="size-6 sm:size-[1.25rem] inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                                data-hs-input-number-decrement=""
                              >
                                <svg
                                  className="flex-shrink-0 size-3.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path d="M5 12h14"></path>
                                </svg>
                              </button>

                              <button
                                type="button"
                                className="size-6 sm:size-[1.25rem] inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                                data-hs-input-number-increment=""
                                onClick={() =>
                                  handleIncrementForQuantity(product, index)
                                }
                              >
                                <svg
                                  className="flex-shrink-0 size-3.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path d="M5 12h14"></path>
                                  <path d="M12 5v14"></path>
                                </svg>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {product.totalStock === 0 ? (
                    <div
                      className={
                        "flex justify-center items-center cursor-not-allowed w-[100%] sm:my-[0.5rem]"
                      }
                    >
                      <button className="bg-[#B7B7B7] cursor-not-allowed text-white text-center h-auto w-[75%] sm:h-[25px] sm:mt-0 xss:w-[100%] relative sm:m-0 my-[0.7rem] rounded-lg  p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem]">
                        Out Of Stock
                      </button>
                    </div>
                  ) : (
                    <div
                      className={
                        product.addToCartStatus === "Update Cart"
                          ? "hidden"
                          : "flex justify-center items-center w-[100%] sm:my-[0.5rem]"
                      }
                    >
                      <button
                        onClick={() => {
                          if (!loadingProducts[product._id]) {
                            handleAddToCart(product, index);
                          }
                        }}
                        className={
                          loadingProducts[product._id]
                            ? "bg-indigo-700 h-[33.13px] text-center w-[75%] sm:h-[25px] sm:mt-0 xss:w-[100%] relative sm:m-0 my-[0.7rem] rounded-lg text-white p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem] hover:bg-blue-600"
                            : "bg-indigo-700 text-center h-auto w-[75%] sm:h-[25px] sm:mt-0 xss:w-[100%] relative sm:m-0 my-[0.7rem] rounded-lg text-white p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem] hover:bg-blue-600"
                        }
                      >
                        {loadingProducts[product._id] ? (
                          <div className="absolute top-[-19px] left-[38%] sm:left-[30%] sm:top-[-19px] h-[45px]">
                            <div className="lds-ring">
                              <div className="sm:!h-[18px] sm:!w-[18px] "></div>
                              <div className="sm:!h-[18px] sm:!w-[18px] "></div>
                              <div className="sm:!h-[18px] sm:!w-[18px] "></div>
                              <div className="sm:!h-[18px] sm:!w-[18px] "></div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {product.addToCartStatus}
                            <FaShoppingCart className="inline mb-[0.2rem] ml-[0.2rem]" />
                          </>
                        )}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
      </div>
      <button
        onClick={scrollLeft}
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        }}
        className="absolute left-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
        aria-label="Scroll Right"
      >
        <MdOutlineKeyboardArrowLeft />
      </button>
    </div>
  );
};

export default ProductForSale;
