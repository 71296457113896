import React, { useCallback, useEffect, useState } from "react";
import {
  FaLocationArrow,

} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { addProduct, setCartLength } from "../../../slice/CartSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IsLoggedIn } from "../../../slice/UserSlice";
import Loader from "../../../common/loader/Loader";
import { BsCart3 } from "react-icons/bs";
import { GetRequest, PostRequest } from "../../../api/Request";
import { showErrorToast, showSuccessToast } from "../../../utils/toast";

interface ProductInfoProps {
  product: any;
  setProduct: any;
  variations: any;
  productsWithSameVariant: any;
  handleSelectVariant: any;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  product,
  setProduct,
  variations,
  productsWithSameVariant,
  handleSelectVariant,
}) => {
  // console.log("%%%%%%%%%Product",productsWithSameVariant)
  const productUrl = window.location.href;
  const [quantity, setQuantity] = useState<number>(1);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("product_id"); // Extract productId from query string
  const [reviewData, setReviewData] = useState({
    avgRating: 0,
  });

  // connect to store
  const [connectStore, setConnectStore] = useState<boolean>(false);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        if (!productId) return; // Ensure productId exists before making the request
        const response = await GetRequest(`/getAllProductReview/${productId}`);
        setReviewData(response.data);
      } catch (error) {
        console.error("Error fetching product reviews:", error);
      }
    };

    fetchReviews();
  }, [productId]); // Dependencies: triggers the effect whenever these change

  const [isInCart, setIsInCart] = useState(false); // State to track if product is added to cart

  let userIsLoggedIn = useSelector(IsLoggedIn);

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(e.target.value));
  };

  const handleDecrementForQuantity = async () => {
    let newProduct = { ...product };

    if (newProduct.quantityByUser > 1) {
      newProduct.quantityByUser -= 1;

      // Update the product state
      setProduct((prevProduct: any) => {
        return { ...prevProduct, product: newProduct };
      });

      const token = localStorage.getItem("userToken"); // Adjust based on where your token is stored
      try {
        if (!token) {
          // No token, save updated quantity to localStorage
          const localCart = JSON.parse(localStorage.getItem("cart") || "[]");
          const existingProductIndex = localCart.findIndex(
            (item: any) => item._id === newProduct._id
          );

          if (existingProductIndex > -1) {
            // Update the quantity for the existing product
            localCart[existingProductIndex].quantityByUser =
              newProduct.quantityByUser;
            localStorage.setItem("cart", JSON.stringify(localCart));
            showSuccessToast("Quantity updated");
          } else {
            showErrorToast("Product not found in cart");
          }
          return; // Exit early since we handled it locally
        }

        // Token exists, make the API call
        const response = await PostRequest("/updateItemQuantity", {
          productId: newProduct?._id, // Use newProduct to ensure correct product ID
          quantity: newProduct.quantityByUser, // Send updated quantity
        });

        if (response.status === 200) {
          // showSuccessToast("Updated quantity");
        } else {
          showErrorToast(
            response.data?.error?.message || "Error updating quantity"
          );
        }
      } catch (error: any) {
        console.error("Error occurred while updating quantity:", error);
        // Show error message from the error response or a generic error message
        showErrorToast(
          error?.response?.data?.message || "An unexpected error occurred"
        );
      }
    }
  };

  const handleIncrementForQuantity = async () => {
    let newProduct = { ...product };

    // Increment quantity if it's less than total stock
    if (newProduct.quantityByUser < newProduct.totalStock) {
      newProduct.quantityByUser += 1;

      // Update the product state
      setProduct((prevProduct: any) => {
        return { ...prevProduct, product: newProduct };
      });

      const token = localStorage.getItem("userToken"); // Adjust based on where your token is stored
      try {
        if (!token) {
          // No token, save updated quantity to localStorage
          const localCart = JSON.parse(localStorage.getItem("cart") || "[]");
          const existingProductIndex = localCart.findIndex(
            (item: any) => item._id === newProduct._id
          );

          if (existingProductIndex > -1) {
            // Update the quantity for the existing product
            localCart[existingProductIndex].quantityByUser =
              newProduct.quantityByUser;
          } else {
            // Add new product to the local cart
            localCart.push({
              ...newProduct,
              quantityByUser: newProduct.quantityByUser,
            });
          }

          localStorage.setItem("cart", JSON.stringify(localCart));
          showSuccessToast("Quantity updated in local cart");
          return; // Exit early since we handled it locally
        }

        // Token exists, make the API call
        const response = await PostRequest("/updateItemQuantity", {
          productId: newProduct?._id, // Use newProduct to ensure correct product ID
          quantity: newProduct.quantityByUser, // Send updated quantity
        });

        if (response.status === 200) {
          // showSuccessToast("Updated quantity");
        } else {
          showErrorToast(
            response.data?.error?.message || "Error updating quantity"
          );
        }
      } catch (error: any) {
        console.error("Error occurred while updating quantity:", error);
        // Show error message from the error response or a generic error message
        showErrorToast(
          error?.response?.data?.message || "An unexpected error occurred"
        );
      }
    }
  };

  const calculateDiscountPercentage = (salePrice: any, regularPrice: any) => {
    if (regularPrice <= salePrice) {
      throw new Error("Regular price must be greater than sale price.");
    }
    const discountPercentage =
      ((regularPrice - salePrice) / regularPrice) * 100;
    return discountPercentage.toFixed(2); // Returns percentage with two decimal places
  };

  const handleAddToCartClick = (product: any) => {
    handleAddToCart(product);
    setIsInCart(true); // Set the product as added to cart
  };

  const handleAddToCart = useCallback(
    async (product: any) => {
      const token = localStorage.getItem("userToken"); // Adjust based on where your token is stored

      setIsAddedToCart(true); // Show loader by setting loading state to true
      try {
        if (!token) {
          // If no token, save to localStorage
          const localCart = JSON.parse(localStorage.getItem("cart") || "[]");
          const existingProductIndex = localCart.findIndex(
            (item: any) => item._id === product._id
          );

          if (existingProductIndex > -1) {
            // Update quantity if product already exists in the cart
            localCart[existingProductIndex].quantityByUser +=
              product.quantityByUser;
          } else {
            // Add new product to the cart
            localCart.push({
              ...product,
              quantityByUser: product.quantityByUser,
            });
          }

          // Save updated cart back to localStorage
          localStorage.setItem("cart", JSON.stringify(localCart));

          // Dispatch action to update cart length
          dispatch(setCartLength(localCart.length));
          showSuccessToast("Product added to cart");

          // Update the product's addToCartStatus in the UI
          setProduct((prevProduct: any) => {
            return {
              ...prevProduct,
              product: {
                ...prevProduct.product,
                addToCartStatus: "Update Cart",
              },
            };
          });
        } else {
          // If token exists, proceed with the API call
          let response = await PostRequest("/addItemToCart", {
            productId: product?._id,
            quantity: product?.quantityByUser,
          });

          if (response.status === 200) {
            // Add product to the Redux store
            dispatch(
              addProduct({
                ...product,
                total: Number(product.salePrice) * product.quantityByUser,
              })
            );

            // Update the product's addToCartStatus in the UI
            setProduct((prevProduct: any) => {
              return {
                ...prevProduct,
                product: {
                  ...prevProduct.product,
                  addToCartStatus: "Update Cart",
                },
              };
            });

            dispatch(setCartLength(response.data.count));
          }
        }
      } catch (error) {
        console.error("Error adding to cart:", error);
      } finally {
        setIsAddedToCart(false);
      }
    },
    [dispatch]
  );

  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=Check out this product: ${encodeURIComponent(
      productUrl
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  function shareOnFacebook() {
    const facebookUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
      productUrl
    )}`;
    window.open(facebookUrl, "_blank");
  }

  function shareOnTwitter() {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      productUrl
    )}&text=Check out this product!`;
    window.open(twitterUrl, "_blank");
  }

  const hadleConnectStore = () => {
    setConnectStore(!connectStore);
  };

  return (
    <>
      <div className="p-6 sm:p-3 bg-white ">
        {isAddedToCart && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
            <Loader /> {/* Your loader component */}
          </div>
        )}

        <h1 className="text-2xl  mb-2 sm:text-sm">{product?.productName} </h1>
        <div className="flex items-center justify-between">
          <Link
            to="/contact"
            className="block text-blue-500 mb-2 text-center flex items-center hover:underline text-xs"
          >
            <FaLocationArrow className="mr-2" />
            Visit Cayro Shop store
          </Link>
        </div>
        <StarRatings rating={reviewData.avgRating} />

        {product?.totalStock !== 0 && (
          <hr className="border-t-2 border-gray-300 my-4 w-[60%] sm:w-full" />
        )}

        <div className="flex flex-col justify-between items-start relative space-x-4 sm:flex lg:flex-row">
          {/* Left Side - Product Information */}
          <div className="flex flex-col justify-center p-[10px] w-full sm:w-[60%]">
            {/* Discount, Price, and Regular Price */}
            <div className="mb-4 flex items-center space-x-4">
              <span className="sm:text-[13px] bg-white text-[#CD0B39]">
                {calculateDiscountPercentage(
                  Number(product?.salePrice),
                  Number(product?.regularPrice)
                ) + "% off"}
              </span>
              <h2 className="text-2xl flex justify-center font-semibold sm:text-sm">
                ₹{product?.salePrice}
              </h2>
              <h2 className="text-md line-through text-red-500 sm:text-sm">
                ₹{product?.regularPrice}
              </h2>
            </div>

            {/* Stock Availability */}
            <div className="flex items-center gap-8 ">
              <p
                className={
                  product?.totalStock === 0
                    ? "text-blue-800 font-semibold text-sm sm:text-xs"
                    : "text-[#009c89] font-semibold text-sm sm:text-xs"
                }
              >
                {product?.totalStock === 0 ? "Out of stock" : "In Stock"}
              </p>

              {/* <div
                className="flex gap-1 items-center cur "
                onClick={hadleConnectStore}
              >
                <MdOutlineLocalGroceryStore className="opacity-80" />

                <button className="font-medium opacity-80">
                  {" "}
                  Connect To Store
                </button>
              </div> */}
              {/* 
              {connectStore &&(
            <ConnectToStore/>

           )} */}
            </div>

            {/* Additional Info (Shipping and EMI) */}
            <div className="flex flex-col gap-[6px] mt-[10px]">
              <h1 className="text-yellow-400 font-bold">Our Services</h1>
              <p className="text-[14px]">Free Shipping on all Orders</p>
              <p className="text-[14px]">No Cost EMI available</p>
            </div>
          </div>

          {/* Right Side - Offers */}
          {product?.totalStock === 0 ? null : (
            <>
              <div className="offer-card block xl:hidden absolute right-[-5%] top-[-32%] sm:relative sm:top-auto sm:right-auto">
                <div className="bg-white shadow-md p-2 w-72 border border-gray-300">
                  <h3 className="text-lg font-semibold mb-4 flex items-center gap-3">
                    <img
                      src="/offers/offer-icon.svg"
                      className="w-[40px] h-[40px]"
                      alt=""
                    />
                    Available Offers
                  </h3>

                  <div className="space-y-2">
                    {/* Main Card for Bank Offer */}
                    <div className="bg-white rounded-lg shadow-sm p-3 border border-gray-200">
                      <div className="flex items-start gap-3">
                        <div>
                          <p className="text-sm font-medium">Bank Offer</p>
                          <p className="text-xs text-gray-600">
                            Upto 5% instant discount on HDFC Bank Credit Cards
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Main Card for Special Price */}
                    <div className="bg-white rounded-lg shadow-sm p-3 border border-gray-200">
                      <div className="flex items-start gap-3">
                        <div>
                          <p className="text-sm font-medium">Special Price</p>
                          <p className="text-xs text-gray-600">
                            Get extra 5% off (price inclusive of discount)
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Main Card for No Cost EMI */}
                    <div className="bg-white rounded-lg shadow-sm p-3 border border-gray-200">
                      <div className="flex items-start gap-3">
                        <div>
                          <p className="text-sm font-medium">No Cost EMI</p>
                          <p className="text-xs text-gray-600">
                            Avail No Cost EMI on select cards for orders above
                            ₹3000
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {product?.totalStock !== 0 && (
          <hr className="border-t-2 border-gray-300 my-4 w-[60%] sm:w-full" />
        )}

        {product?.totalStock !== 0 && (
          <div className="sm:flex-col flex flex-col items-start gap-[10px]">
            <div className="flex flex-col sm:flex">
              {isInCart &&
                product?.quantityByUser > 0 && ( // Show section only if quantity is greater than 0
                  <div className="flex items-center space-x-2 mb-4">
                    <button
                      type="button"
                      onClick={handleDecrementForQuantity}
                      className="p-2 px-6 text-[17px] flex justify-center items-center sm:px-2 bg-gray-200 rounded sm:h-[28px] text-bold"
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="w-16 sm:w-8 p-2 text-[17px] border sm:h-[28px] rounded text-center"
                      value={product?.quantityByUser}
                      onChange={handleQuantityChange}
                      readOnly // Optionally make it read-only to prevent manual input
                    />
                    <button
                      type="button"
                      onClick={handleIncrementForQuantity}
                      className="p-2 sm:p-3 flex justify-center items-center px-6 bg-gray-200 sm:h-[28px] rounded text-bold"
                    >
                      +
                    </button>
                  </div>
                )}

              {isInCart && product?.quantityByUser === product?.totalStock && (
                <h4
                  className="text-xs text-center text-purple-600 mb-[0.6rem]"
                  id="hs-validation-name-error-helper"
                >
                  Only {product?.totalStock} left in stock
                </h4>
              )}
            </div>
            <div className="flex space-x-4">
              <button
                onClick={() => handleAddToCartClick(product)}
                className="border sm:min-w-[120px] sm:text-xs border-blue-500 text-blue-500 sm:px-2 sm:py-1 px-4 py-2 rounded flex-1 min-w-[184px] hover:bg-white hover:text-blue-500"
              >
                Add to cart
                <BsCart3 className="inline mb-[0.2rem] ml-[0.2rem]" />
                {product?.isLoading && (
                  <div className="absolute top-[-18px] left-[14px]">
                    <Loader />
                  </div>
                )}
              </button>

              <button
                type="submit"
                onClick={() => {
                  handleAddToCartClick(product);
                  navigate("/cart");
                }}
                className="border sm:min-w-[120px] rounded-lg transition-transform duration-300 transform sm:text-xs text-white bg-blue-500 px-4 py-2 rounded flex-1 min-w-[184px] hover:scale-105"
              >
                Buy Now
              </button>
            </div>
          </div>
        )}
        {product?.totalStock !== 0 && (
          <hr className="border-t-2 border-gray-300 my-4 w-[60%] sm:w-full" />
        )}

        {product?.salePrice ? (
          <div className="mt-4">
            <ul className="flex justify-evenly items-end gap-2 ">
              {/* Free Shipping */}
              <li className="flex flex-col items-center text-center">
                <img
                  src="/productInfo/fast_delivery.svg"
                  className="w-[30px] xss:w-[35px] xs:w-[40px] sm:w-[25px] lg:w-[30px]"
                  alt="Fast Delivery"
                />
                <h1 className="mt-2 text-[12px] xss:text-[11px] xs:text-[12px] sm:text-[11px] lg:text-[13px] text-cyan-400">
                  Free shipping
                </h1>
              </li>

              {/* No Cost EMI */}
              <li className="flex flex-col items-center text-center">
                <img
                  src="/productInfo/Group.svg"
                  className="w-[25px] xss:w-[30px] xs:w-[35px] sm:w-[40px] lg:w-[25px]"
                  alt="No Cost EMI"
                />
                <h1 className="mt-2 text-[12px] xss:text-[11px] xs:text-[12px] sm:text-[13px] lg:text-[14px] text-cyan-400">
                  No Cost EMI
                </h1>
              </li>

              {/* Top Brand */}
              <li className="flex flex-col items-center text-center">
                <img
                  src="/productInfo/top_brand.svg"
                  className="w-[30px] xss:w-[35px] xs:w-[40px] sm:w-[25px] lg:w-[30px]"
                  alt="Top Brand"
                />
                <h1 className="mt-2 text-[12px] xss:text-[11px] xs:text-[12px] sm:text-[13px] lg:text-[14px] text-cyan-400">
                  Top Brand
                </h1>
              </li>

              {/* Cayro Delivery */}
              <li className="flex flex-col items-center text-center">
                <img
                  src="/productInfo/cayro_delivery.svg"
                  className="w-[30px] xss:w-[35px] xs:w-[40px] sm:w-[25px] lg:w-[30px]"
                  alt="Cayro Delivery"
                />
                <h1 className="mt-2 text-[12px] xss:text-[11px] xs:text-[12px] sm:text-[13px] lg:text-[14px] text-cyan-400">
                  Cayro Delivery
                </h1>
              </li>

              {/* Secure Transaction */}
              <li className="flex flex-col items-center text-center">
                <img
                  src="/productInfo/secure_transaction.svg"
                  className="w-[30px] xss:w-[35px] xs:w-[40px] sm:w-[25px] lg:w-[30px]"
                  alt="Secure Transaction"
                />
                <h1 className="mt-2 text-[12px] xss:text-[11px] xs:text-[12px] sm:text-[13px] lg:text-[14px] text-cyan-400">
                  Secure Transaction
                </h1>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
        {product?.totalStock !== 0 && (
          <hr className="border-t-2 border-gray-300 my-4 w-[105%] sm:w-full" />
        )}
<div className="mt-[10px] flex space-x-6 sm:flex sm:flex-col sm:space-x-0 sm:space-y-4">
  {
    variations?.length > 0 && variations.some((variation: any) => variation?.value?.length > 0) ? (
      variations.map((variation: any) => {
        console.log("Variation:", variation); // Log each variation

        // Check if the variation name is 'undefined' or if the value array is empty
        if (!variation?.name || variation?.name === 'undefined' || !variation?.value || variation?.value.length === 0) {
          return null; // Don't render this variation if invalid
        }
        
        return (
          <div key={variation?.name}>
            <h5 className="text-gray-600 font-medium text-[12px] mb-2">
              {variation?.name}:
            </h5>
            <ul className="flex items-center gap-4 overflow-x-auto scrollbar-hide ">
              {variation.value.map((value: string) => {
                const isSelected = product?.variation?.some(
                  (varItem: any) =>
                    varItem.name === variation.name && varItem.value === value
                );
                
                console.log("Value:", value); // Log each value in the variation
                
                return (
                  <li
                    key={value}
                    onClick={() => handleSelectVariant(variation, value)}
                    className={`text-[12px] cursor-pointer rounded-xl px-2 py-1 text-sm font-medium transition-all duration-300 whitespace-nowrap ${isSelected
                        ? "bg-blue-600 text-white hover:bg-blue-500"
                        : "border border-gray-300 text-gray-700 bg-white hover:bg-gray-100"
                      }`}
                  >
                    {value}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })
    ) : null}
</div>




      </div>
      {/* <div className="relative">
        <div className="bottom-0 right-0 fixed z-10">
          <div onClick={hadleConnectStore} className="cursor-pointer">
          <Lottie
          animationData={CallNow}
          className="w-32 h-32"
        />
          </div>
        </div>

        {connectStore && (
          <div className="fixed right-5  bottom-28 z-20 ">
            <ConnectToStore />
          </div>
        )}
      </div> */}
    </>
  );
};

const StarRatings = ({ rating }: { rating: number }) => {
  const fullStars = Math.floor(5); // Number of full stars
  const halfStar = rating % 1 >= 0.5; // Whether to show a half star
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Remaining empty stars

  return (
    <div className="flex gap-[5px]">
      {/* Full stars */}
      {Array.from({ length: fullStars }).map((_, index) => (
        <span key={`full-${index}`} className="text-yellow-500">
          ★
        </span>
      ))}

      {/* Half star */}
      {halfStar && <span className="text-yellow-500">☆</span>}

      {/* Empty stars */}
      {Array.from({ length: emptyStars }).map((_, index) => (
        <span key={`empty-${index}`} className="text-gray-300">
          ★
        </span>
      ))}
    </div>
  );
};

export default ProductInfo;
